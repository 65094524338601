const targetTranslation = {
  simulator: "Simulator (Always Enabled)"
};
targetTranslation["qpu.forte-1"] = "Forte 1";
targetTranslation["qpu.forte-enterprise-1"] = "Forte Enterprise 1";
targetTranslation["qpu.forte-enterprise-2"] = "Forte Enterprise 2";
targetTranslation["qpu.aria-1"] = "Aria 1";
targetTranslation["qpu.aria-2"] = "Aria 2";
export const getFriendlyTargetName = (qpuSlug) => {
  let res = targetTranslation[qpuSlug];
  if (res) {
    return res;
  }
  return qpuSlug;
};
export const getSortedFriendlyTargets = (targets) => {
  const res = targets.map((t) => ({
    target: t,
    name: getFriendlyTargetName(t)
  }));
  return res.sort(sortQpuNames);
};
export const sortQpuNames = (a, b) => {
  if (a.target === "simulator") {
    return -1;
  } else if (b.target === "simulator") {
    return 1;
  }
  return a.name.localeCompare(b.name);
};
