import { Tr } from "@chakra-ui/react";
import { noop } from "@tanstack/react-table";
import { IonTableCheckbox } from "../IonTableCheckbox.js";
import React from "react";
import { rowVisibleCellsMapFunction } from "./rowVisibleCellsMapFunction.js";
export const tableRowModelMapCellsFunction = ({
  currentPageIndex,
  isRowSelectEnabled,
  limit,
  onRowClick,
  row,
  toast,
  toastIdRef
}, index) => {
  if (limit !== void 0 && currentPageIndex !== void 0) {
    if (index > limit * (currentPageIndex + 1) - 1 || index < limit * currentPageIndex) {
      return null;
    }
  }
  return /* @__PURE__ */ React.createElement(
    Tr,
    {
      key: row.id,
      onClick: onRowClick ? () => onRowClick(row.original) : noop
    },
    isRowSelectEnabled && /* @__PURE__ */ React.createElement(
      IonTableCheckbox,
      {
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        onChange: row.getToggleSelectedHandler()
      }
    ),
    row.getVisibleCells().map((cell, cellIndex) => {
      return {
        cell,
        toast,
        toastIdRef,
        isRowSelectEnabled,
        cellIndex
      };
    }).map(rowVisibleCellsMapFunction)
  );
};
