import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { useMyJobs } from "../components/Projects/hooks/useMyJobs.js";
import {
  MY_JOBS_PAGE_SIZE,
  DETAIL_PAGE_SIZE_KEY,
  MY_JOBS_PAGE_SIZE_KEY,
  DETAIL_PAGE_SIZE
} from "../components/shared/IonTable/pageSize.js";
import { useLocation } from "react-router-dom";
export const MyJobsContext = createContext({
  jobs: [],
  setJobs: () => {
  },
  filters: [],
  setFilters: () => {
  },
  hasMore: false,
  next: null,
  isLoading: false,
  jobsError: null,
  loadMore: () => {
  },
  setIsLoading: () => {
  },
  pageSizeKey: ""
});
export const MyJobsProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isDetailPage = pathname.split("/").filter(Boolean).length > 1;
  const [filters, setFilters] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [pageSizeKey, setPageSizeKey] = useState(
    isDetailPage ? DETAIL_PAGE_SIZE_KEY : MY_JOBS_PAGE_SIZE_KEY
  );
  const [limit, setLimit] = useState(
    isDetailPage ? DETAIL_PAGE_SIZE() : MY_JOBS_PAGE_SIZE()
  );
  const {
    hasMore,
    next,
    jobs,
    isLoading,
    loadMore,
    error: jobsError,
    setIsLoading
  } = useMyJobs({
    filters,
    limit
  });
  useEffect(() => {
    const splitURL = pathname.split("/").filter(Boolean);
    const [jobsPath, id] = splitURL;
    const isDetailPage2 = splitURL.length > 1;
    if (limit !== (isDetailPage2 ? DETAIL_PAGE_SIZE() : MY_JOBS_PAGE_SIZE())) {
      setLimit(isDetailPage2 ? DETAIL_PAGE_SIZE() : MY_JOBS_PAGE_SIZE());
    }
    if (pageSizeKey !== (isDetailPage2 ? DETAIL_PAGE_SIZE_KEY : MY_JOBS_PAGE_SIZE_KEY)) {
      setPageSizeKey(
        isDetailPage2 ? DETAIL_PAGE_SIZE_KEY : MY_JOBS_PAGE_SIZE_KEY
      );
    }
    if (splitURL.length !== 1 && splitURL.length !== 2 || id === jobId || jobsPath !== "jobs" || id && id.length !== 36)
      return;
    setIsLoading(true);
    setJobId(id);
  }, [location.pathname, jobId, setIsLoading, setJobId, limit, pageSizeKey]);
  return /* @__PURE__ */ React.createElement(
    MyJobsContext.Provider,
    {
      value: {
        jobs,
        filters,
        setFilters,
        hasMore,
        next,
        isLoading,
        jobsError,
        loadMore,
        setIsLoading,
        limit,
        setLimit,
        pageSizeKey
      }
    },
    children
  );
};
