import { Box } from "@chakra-ui/react";
import React from "react";
export const IonPanel = ({ children }) => {
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      p: "16px 24px",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px"
    },
    children
  );
};
