const JOBS_DEFAULT_PAGE_SIZE = 50;
const JOBS_DETAIL_DEFAULT_PAGE_SIZE = 15;
export const MY_JOBS_PAGE_SIZE_KEY = "MY_JOBS_PAGE_SIZE";
export const PROJECT_JOBS_PAGE_SIZE_KEY = "PROJECT_JOBS_PAGE_SIZE";
export const DETAIL_PAGE_SIZE_KEY = "DETAIL_PAGE_SIZE";
export const MY_JOBS_PAGE_SIZE = () => {
  const myJobsPageSize = localStorage.getItem(MY_JOBS_PAGE_SIZE_KEY);
  const pageSize = Number(myJobsPageSize);
  if (myJobsPageSize && !isNaN(pageSize) && pageSize >= 15 && pageSize <= 100) {
    return Number(myJobsPageSize);
  }
  return JOBS_DEFAULT_PAGE_SIZE;
};
export const PROJECT_JOBS_PAGE_SIZE = () => {
  const projectPageSize = localStorage.getItem(PROJECT_JOBS_PAGE_SIZE_KEY);
  const pageSize = Number(projectPageSize);
  if (projectPageSize && !isNaN(pageSize) && pageSize >= 15 && pageSize <= 100) {
    return Number(projectPageSize);
  }
  return JOBS_DEFAULT_PAGE_SIZE;
};
export const DETAIL_PAGE_SIZE = () => {
  const detailPageSize = localStorage.getItem(DETAIL_PAGE_SIZE_KEY);
  const pageSize = Number(detailPageSize);
  if (detailPageSize && !isNaN(pageSize) && pageSize >= 15 && pageSize <= 100) {
    return Number(detailPageSize);
  }
  return JOBS_DETAIL_DEFAULT_PAGE_SIZE;
};
