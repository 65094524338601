import React from "react";
import { Tag, Text } from "@chakra-ui/react";
const Switch = (props) => {
  const { test, children } = props;
  return children.find((child) => child.props.value === test);
};
export const JobTag = ({ id, type, circuits }) => {
  return /* @__PURE__ */ React.createElement(
    Tag,
    {
      size: "sm",
      key: id,
      variant: "outline",
      borderColor: "gray.500",
      borderRadius: "5px",
      bg: "white"
    },
    /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9", color: "gray.700" }, type === "circuit" && typeof circuits === "number" && circuits > 1 && "multicircuit", type === "circuit" && typeof circuits === "number" && circuits <= 1 && "circuit", type === "circuit" && circuits === void 0 && "N/A", type && type !== "circuit" && /* @__PURE__ */ React.createElement(Switch, { test: type }, /* @__PURE__ */ React.createElement(Text, { value: "quantum-function" }, "q.function"), /* @__PURE__ */ React.createElement(Text, { value: "json" }, "circuit"), /* @__PURE__ */ React.createElement(Text, { value: "ionq.circuit.v0" }, "circuit"), /* @__PURE__ */ React.createElement(Text, { value: "ionq.optimization.v0" }, "optimization"), /* @__PURE__ */ React.createElement(Text, { value: "ionq.apps.hybrid.optimization.v0" }, "optimization"), /* @__PURE__ */ React.createElement(Text, { value: type }, type)), !type && "N/A")
  );
};
