import { useState, useEffect } from "react";
import { useApi } from "../../../Api/ApiProvider";
export const useJobProgress = ({ jobId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!jobId || isApiLoading) return;
    setIsLoading(true);
    Api.getJobProgress({
      jobId,
      onSuccess: (res) => {
        setProgress(res);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(e);
        setIsLoading(false);
      }
    });
  }, [jobId, Api, isApiLoading]);
  return {
    progress,
    error,
    isLoading
  };
};
