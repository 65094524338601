import { Button, Center, Flex, Select, Spacer, Text } from "@chakra-ui/react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import React, { useContext } from "react";
export const PreviousAndNext = ({
  rows,
  currentPageIndex,
  setCurrentPageIndex,
  context
}) => {
  const { loadMore, hasMore, limit, setLimit, pageSizeKey } = useContext(context);
  const firstIndexOfThisPage = limit * currentPageIndex + 1;
  const firstIndexOfNextPage = Number(limit) * (currentPageIndex + 1);
  const isLastPage = Math.min(firstIndexOfNextPage, rows.length) === rows.length;
  return /* @__PURE__ */ React.createElement(Flex, { mb: "32px" }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "body-5",
      color: "gray.600",
      noOfLines: 1,
      mr: "12px"
    },
    "Jobs per page"
  ), /* @__PURE__ */ React.createElement(
    Select,
    {
      "data-testid": "PreviousNextSelect",
      size: "sm",
      w: "80px",
      borderRadius: "5px",
      textStyle: "body-3",
      value: localStorage.getItem(pageSizeKey) || limit,
      onChange: (e) => {
        localStorage.setItem(pageSizeKey, e.target.value);
        setLimit(Number(e.target.value));
        setCurrentPageIndex(0);
        if (Number(limit) * currentPageIndex + 1 > rows.length) {
          loadMore();
        }
      }
    },
    /* @__PURE__ */ React.createElement("option", { value: "15" }, 15),
    /* @__PURE__ */ React.createElement("option", { value: "25" }, 25),
    /* @__PURE__ */ React.createElement("option", { value: "50" }, 50),
    /* @__PURE__ */ React.createElement("option", { value: "100" }, 100)
  )), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5", color: "gray.600", noOfLines: 1 }, `${Math.min(
    firstIndexOfThisPage,
    rows.length
  )} - ${Math.min(
    firstIndexOfNextPage,
    rows.length
  )} of ${rows.length} ${rows.length === 1 ? "job" : "jobs"}`), /* @__PURE__ */ React.createElement(
    Button,
    {
      "data-testid": "PreviousNextPreviousButton",
      isDisabled: currentPageIndex === 0,
      variant: "white",
      h: "32px",
      onClick: () => setCurrentPageIndex(currentPageIndex - 1),
      ml: "12px",
      leftIcon: /* @__PURE__ */ React.createElement(MdArrowBackIos, null)
    },
    /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5", color: "ionDark" }, "Previous")
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      "data-testid": "PreviousNextNextButton",
      isDisabled: isLastPage,
      variant: "white",
      minW: "88px",
      h: "32px",
      ml: "12px",
      onClick: () => {
        if (hasMore || firstIndexOfThisPage > rows.length) {
          loadMore();
        }
        setCurrentPageIndex(currentPageIndex + 1);
      },
      rightIcon: /* @__PURE__ */ React.createElement(MdArrowForwardIos, null)
    },
    /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5", color: "ionDark" }, "Next")
  )));
};
