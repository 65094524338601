import { useCallback, useEffect, useState } from "react";
import { getStandardError } from "../components/shared/utils/utils.js";
import { useApi } from "../Api/ApiProvider.js";
import { createJobsParamsObject } from "../util/createJobsParamsObject.js";
export const useJobs = ({ filters, limit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [next, setNext] = useState(null);
  const [error, setError] = useState(null);
  const [jobId, setJobId] = useState(filters?.parentId || null);
  const params = createJobsParamsObject({
    filters,
    limit: jobs.length === 0 ? 200 : Math.max(limit, 100)
  });
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!isLoading || isApiLoading) return;
    Api.getJobs({
      params,
      onSuccess: (res) => {
        setJobs(res.jobs);
        setNext(res.next);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(getStandardError(e));
        setIsLoading(false);
      }
    });
  }, [params, isLoading, Api, isApiLoading]);
  const loadMore = useCallback(() => {
    Api.getJobs({
      params: next ? {
        ...params,
        next
      } : { ...params },
      onSuccess: (res) => {
        setJobs(jobs.concat(res.jobs));
        setNext(res.next);
      },
      onError: (e) => {
        setError(getStandardError(e));
      }
    });
  }, [jobs, params, next, setJobs, Api]);
  useEffect(() => {
    if (filters?.parentId === jobId) return;
    setIsLoading(true);
    setJobId(filters?.parentId);
  }, [filters?.parentId, jobId, setJobId]);
  return {
    hasMore: !!next,
    next,
    jobs,
    error,
    isLoading,
    setIsLoading,
    loadMore
  };
};
