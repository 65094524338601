export const nameMapping = {
  "forte-1": "IonQ Forte",
  "aria-1": "IonQ Aria 1",
  "aria-2": "IonQ Aria 2",
  harmony: "IonQ Harmony",
  simulator: "Simulator"
};
export const aqMapping = {
  "forte-1": 36,
  "aria-1": 25,
  "aria-2": 25,
  harmony: 9,
  simulator: null
};
export const getAvgTimeInQueue = (average_queue_time) => {
  const totalSeconds = Math.floor(average_queue_time / 1e3);
  const days = Math.floor(totalSeconds / (24 * 3600));
  const hours = Math.floor(totalSeconds % (24 * 3600) / 3600);
  const minutes = Math.floor(totalSeconds % 3600 / 60);
  return minutes ? `${days ? `${days}d ` : ""}${hours ? `${hours}hrs ` : ""}${minutes ? `${minutes}min` : ""}` : "0min";
};
export const formatInvertedFidelity = (fidelity, key) => {
  return fidelity && fidelity[key] ? `${(100 - fidelity[key].mean * 100).toFixed(3)}% (avg)` : "Unknown";
};
export const formatGateFidelity = (fidelity, key) => {
  return fidelity && fidelity[key] ? `${(100 - fidelity[key].mean * 100).toFixed(3)}% (avg)` : "Unknown";
};
export const formatTiming = (timing, key) => {
  return timing && timing[key] ? `${timing[key] * 10 ** 6} \xB5s` : "Unknown";
};
export const getTabIndex = (tab) => {
  return tab === "simulator" ? 1 : 0;
};
export const addAQ = (item) => {
  return {
    ...item,
    aq: aqMapping[item.backend.split(".").pop()] ?? item.qubits
  };
};
export const backendSort = (a, b) => {
  if (a.aq < b.aq) {
    return 1;
  } else if (a.aq > b.aq) {
    return -1;
  } else if (a.backend > b.backend) {
    return 1;
  } else if (a.backend < b.backend) {
    return -1;
  }
  return 0;
};
