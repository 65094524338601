import { Text } from "@chakra-ui/react";
import React from "react";
import { IonTooltip } from "../IonTooltip/index.js";
const MAX_NAME_LENGTH = 100;
export const ProjectNameCell = ({ name }) => {
  const element = /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "body-table-name",
      color: "ionDark",
      noOfLines: 2,
      wordBreak: "break-all",
      maxW: "456px"
    },
    name
  );
  return name?.length > MAX_NAME_LENGTH ? /* @__PURE__ */ React.createElement(IonTooltip, { label: name }, element) : element;
};
