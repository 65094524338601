import { useBaseModal } from "./baseModals/useBaseModal.js";
import { MODALS } from "./baseModals/constants.js";
import { useClipboard } from "@chakra-ui/react";
import { RowActionPopover } from "./RowActionPopover.js";
import { MdHighlightOff, MdOutlineFileDownload } from "react-icons/md";
import React from "react";
export const JobsRowActionPopover = ({ job }) => {
  const { open: openCancelJobConfirmationModal } = useBaseModal(
    MODALS.cancelJobConfirmation
  );
  const { onCopy } = useClipboard(job.id);
  const jobIsComplete = [
    "canceled",
    "completed",
    "errored",
    "failed"
  ].includes(job.status);
  const jobIsCancelable = ["submitted", "ready"].includes(job.status);
  const downloadJobFromClickEvent = (e) => {
    e.stopPropagation();
    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(job)], {
      type: "text/plain"
    });
    a.href = URL.createObjectURL(file);
    a.download = `${job.id}.txt`;
    a.click();
  };
  const cancelJobFromClickEvent = (e) => {
    e.stopPropagation();
    openCancelJobConfirmationModal({ jobId: job.id });
  };
  const menuItems = [
    {
      key: "download",
      node: "Download complete response",
      onClick: downloadJobFromClickEvent
    },
    {
      key: "view",
      node: "View job details",
      onClick: () => {
      }
    },
    {
      key: "copy",
      node: "Copy job ID to clipboard",
      onClick: (e) => {
        e.stopPropagation();
        onCopy();
      }
    },
    job.status !== "completed" && job.status !== "failed" && job.status !== "canceled" && {
      key: "cancel",
      node: "Cancel job",
      variant: "DANGER",
      onClick: cancelJobFromClickEvent
    }
  ].filter(Boolean);
  return /* @__PURE__ */ React.createElement(
    RowActionPopover,
    {
      onClickEvent: (e) => {
        if (jobIsComplete) {
          downloadJobFromClickEvent(e);
        } else {
          cancelJobFromClickEvent(e);
        }
      },
      icon: (jobIsCancelable || jobIsComplete) && (jobIsCancelable ? MdHighlightOff : MdOutlineFileDownload) || null,
      ariaLabel: jobIsCancelable ? "Cancel Job" : "Download Job",
      variant: jobIsCancelable ? "CRITICAL" : "DEFAULT",
      menuItems
    }
  );
};
