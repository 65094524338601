import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Formik } from "formik";
import { MdFilterList } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
const defaultValues = {
  status: "",
  name: "",
  id: "",
  request: "",
  submitted: "",
  dateCheckbox: false,
  statusCheckbox: false,
  nameCheckbox: false,
  requestCheckbox: false,
  metadataCheckbox: false,
  idCheckbox: false,
  submittedCheckbox: false,
  filterDate: "",
  filterEndDate: "",
  metadataKey: "",
  metadataValue: ""
};
export const FilterButton = ({ filters, setFilters, filterOptions = [] }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const isFiltering = Object.keys(filters).map((x) => filters[x]).filter(Boolean).length;
  return /* @__PURE__ */ React.createElement(
    Popover,
    {
      placement: "bottom-start",
      isOpen,
      onOpen,
      onClose
    },
    /* @__PURE__ */ React.createElement(PopoverTrigger, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        "data-testid": "FilterButton",
        size: "md",
        color: isFiltering ? "uiBlue" : "unset",
        variant: "grey",
        leftIcon: /* @__PURE__ */ React.createElement(
          Icon,
          {
            as: MdFilterList,
            width: "16px",
            height: "16px",
            color: isFiltering ? "uiBlue" : "gray.600"
          }
        )
      },
      isFiltering ? "Filtering" : "Filter"
    )),
    /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement(
      PopoverContent,
      {
        maxWidth: "300px",
        "data-testid": "FilterButtonPopover"
      },
      /* @__PURE__ */ React.createElement(PopoverBody, { m: 0, p: 0 }, /* @__PURE__ */ React.createElement(
        Formik,
        {
          initialValues: defaultValues,
          onSubmit: (values) => {
            setFilters(values);
          }
        },
        ({
          dirty,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          resetForm,
          setValues
        }) => {
          return /* @__PURE__ */ React.createElement(
            Box,
            {
              borderRadius: "5px",
              bg: "white",
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)"
            },
            /* @__PURE__ */ React.createElement(
              Flex,
              {
                borderRadius: "5px",
                borderBottomEndRadius: 0,
                borderBottomStartRadius: 0,
                padding: "8px 16px 0",
                border: "1px solid #e8e8e8",
                bg: "#f3f3f3",
                maxHeight: "46px"
              },
              /* @__PURE__ */ React.createElement(
                Button,
                {
                  "data-testid": "FilterClearButton",
                  size: "sm",
                  variant: "grey",
                  onClick: () => {
                    setFilters({});
                    setValues(defaultValues);
                    resetForm();
                  }
                },
                "Clear"
              ),
              /* @__PURE__ */ React.createElement(Spacer, null),
              /* @__PURE__ */ React.createElement(
                Button,
                {
                  "data-testid": "FilterApplyButton",
                  size: "sm",
                  variant: "blue",
                  mb: 3,
                  type: "submit",
                  onClick: () => {
                    handleSubmit(values);
                    onClose();
                  }
                },
                "Apply"
              )
            ),
            filterOptions.map(
              ({
                name,
                options,
                property,
                isDate,
                isMetadata,
                label
              }) => /* @__PURE__ */ React.createElement(
                CheckboxSelectInputFormFilter,
                {
                  dirty,
                  key: name,
                  isMetadata,
                  isDate,
                  handleBlur,
                  handleChange,
                  options,
                  name,
                  property,
                  values,
                  setValues,
                  label
                }
              )
            )
          );
        }
      ))
    ))
  );
};
const CheckboxSelectInputFormFilter = ({
  handleBlur,
  handleChange,
  isDate = false,
  isMetadata = false,
  name = "",
  options,
  property,
  values,
  dirty,
  setValues,
  label = ""
}) => {
  const checkboxName = `${property}Checkbox`;
  const isSelect = Boolean(options?.length);
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      borderRadius: "0",
      padding: "8px 16px",
      border: "1px solid #e8e8e8",
      borderTop: "0px"
    },
    /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        "data-testid": `Checkbox-${name}`,
        name: checkboxName,
        colorScheme: "blue",
        size: "sm",
        fontWeight: "500",
        color: "gray.500",
        onChange: (e) => {
          setValues({ ...values, [property]: "" });
          handleChange(e);
        },
        onBlur: handleBlur,
        isChecked: values[checkboxName]
      },
      name
    ),
    values?.[checkboxName] ? isDate ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Select,
      {
        size: "xs",
        name: property,
        onChange: handleChange,
        onBlur: handleBlur,
        value: values[property]
      },
      ["", "equals", "before", "after", "between"].map(
        (item) => {
          return /* @__PURE__ */ React.createElement(
            "option",
            {
              key: item.toLowerCase(),
              value: item.toLowerCase()
            },
            item ? item : `Choose setting`
          );
        }
      )
    ), /* @__PURE__ */ React.createElement(Flex, { mt: "8px" }, /* @__PURE__ */ React.createElement(
      Input,
      {
        "data-testid": `Input-${name}`,
        size: "xs",
        display: "inline-block",
        name: "filterDate",
        onChange: handleChange,
        onBlur: handleBlur,
        isInvalid: dirty.filterDate && (values.filterDate?.length !== 10 || !values.filterDate.test(
          /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
        )),
        placeholder: "YYYY-MM-DD"
      }
    ), values[property] === "between" && /* @__PURE__ */ React.createElement(
      Input,
      {
        size: "xs",
        display: "inline-block",
        ml: "8px",
        name: "filterEndDate",
        isInvalid: dirty.filterEndDate && (values.filterEndDate < values.filterDate || !values.filterEndDate.test(
          /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
        )),
        onChange: handleChange,
        onBlur: handleBlur,
        placeholder: "YYYY-MM-DD"
      }
    ))) : isSelect ? /* @__PURE__ */ React.createElement(
      Select,
      {
        size: "xs",
        name: property,
        onChange: handleChange,
        onBlur: handleBlur,
        value: values[property]
      },
      [""].concat(options).map((item) => {
        if (typeof item === "string") {
          return /* @__PURE__ */ React.createElement(
            "option",
            {
              key: item.toLowerCase(),
              value: item.toLowerCase()
            },
            item ? item : `Choose ${name}`
          );
        }
        return /* @__PURE__ */ React.createElement(
          "option",
          {
            key: item.value.toLowerCase(),
            value: item.value.toLowerCase()
          },
          item.label ? item.label : `Choose ${name}`
        );
      })
    ) : isMetadata ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9", mb: "4px" }, "key"), /* @__PURE__ */ React.createElement(
      Input,
      {
        size: "sm",
        name: "metadataKey",
        onChange: handleChange,
        onBlur: handleBlur
      }
    ), values.metadataKey && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9", mt: "8px", mb: "4px" }, "value (optional)"), /* @__PURE__ */ React.createElement(
      Input,
      {
        size: "sm",
        name: "metadataValue",
        onChange: handleChange,
        onBlur: handleBlur
      }
    ))) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9", mb: "4px" }, label), /* @__PURE__ */ React.createElement(
      Input,
      {
        "data-testid": `Input-${name}`,
        size: "sm",
        name: property,
        onChange: handleChange,
        onBlur: handleBlur,
        value: values[property]
      }
    )) : null
  );
};
