import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { JobsDashboard } from "./JobsDashboard";
import { useJob } from "../CancelJobConfirmationModal/useJob.js";
import { AbsoluteCenter, Spinner } from "@chakra-ui/react";
import { JobDetail } from "./JobDetail.js";
export const Jobs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, jobId] = pathname.split("/").filter(Boolean);
  const { job, loading: isJobLoading, error: jobError } = useJob(jobId);
  if (jobId && !jobError && (isJobLoading || !job.id)) {
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, null));
  }
  if (jobError) {
    return navigate("/404", { replace: true });
  }
  return /* @__PURE__ */ React.createElement("div", { id: "jobs" }, /* @__PURE__ */ React.createElement(Routes, null, /* @__PURE__ */ React.createElement(Route, { path: "/", element: /* @__PURE__ */ React.createElement(JobsDashboard, null) }), /* @__PURE__ */ React.createElement(Route, { path: "/:id", element: /* @__PURE__ */ React.createElement(JobDetail, null) })));
};
