import { chakra, Th } from "@chakra-ui/react";
import { flexRender } from "@tanstack/react-table";
import { IonTooltip } from "../IonTooltip/index.js";
import React, { useState } from "react";
import { SortIcon } from "./SortIcon.js";
export const headerGroupHeaderMapFunction = ({
  header,
  index,
  isRowSelectEnabled
}) => {
  const meta = header.column.columnDef.meta;
  const [isSorting, setIsSorting] = useState(false);
  const { info, isSortable } = meta || {};
  return /* @__PURE__ */ React.createElement(
    Th,
    {
      key: header.id,
      color: "gray.600",
      padding: !isRowSelectEnabled && index === 0 ? "0 24px" : "0 12px"
    },
    flexRender(header.column.columnDef.header, header.getContext()),
    info && /* @__PURE__ */ React.createElement(IonTooltip, { label: info }),
    isSortable && /* @__PURE__ */ React.createElement(chakra.span, { pl: "1px" }, /* @__PURE__ */ React.createElement(
      SortIcon,
      {
        isSorted: isSorting,
        onClick: () => {
          header.column.toggleSorting(isSorting);
          setIsSorting(!isSorting);
        }
      }
    ))
  );
};
