import { useCallback, useEffect, useState } from "react";
import { getStandardError } from "../../shared/utils/utils";
import { useApi } from "../../../Api/ApiProvider";
import { createProjectJobsParamsObject } from "../../../util/createProjectJobsParamsObject.js";
export const useProjectJobs = ({ filters, id, limit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [next, setNext] = useState(null);
  const [error, setError] = useState(null);
  const [projectId, setProjectId] = useState(id);
  const params = createProjectJobsParamsObject({
    projectId,
    filters,
    limit: jobs.length === 0 ? 200 : Math.max(limit, 100)
  });
  const { Api, isLoading: isApiLoading } = useApi();
  useEffect(() => {
    if (!isLoading || isApiLoading || !projectId) return;
    Api.getJobs({
      params,
      onSuccess: (res) => {
        setJobs(res.jobs);
        setNext(res.next);
        setIsLoading(false);
      },
      onError: (e) => {
        setError(getStandardError(e));
        setIsLoading(false);
      }
    });
  }, [params, isLoading, Api, isApiLoading, projectId]);
  const loadMore = useCallback(() => {
    Api.getJobs({
      params: {
        ...params,
        next
      },
      onSuccess: (res) => {
        setJobs(jobs.concat(res.jobs));
        setNext(res.next);
      },
      onError: (e) => {
        setError(getStandardError(e));
      }
    });
  }, [jobs, params, next, setJobs, Api]);
  return {
    hasMore: !!next,
    next,
    jobs,
    error,
    isLoading,
    setIsLoading,
    loadMore,
    projectId,
    setProjectId
  };
};
