import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";
import QLogo from "../shared/QLogo";
import { HeaderExtendedMenu } from "./HeaderExtendedMenu";
import { SESSION_COOKIE_NAME } from "../../api";
export const Header = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuIsOpen(false);
  };
  const { pathname } = useLocation();
  const isLoggedIn = Cookies.get(SESSION_COOKIE_NAME);
  return isLoggedIn && /* @__PURE__ */ React.createElement("div", { className: "header-container" }, /* @__PURE__ */ React.createElement("div", { className: "header" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `header-content ${mobileMenuIsOpen ? "-expanded" : ""} ${pathname === "/scratchpad" ? "-big-menu" : ""}`
    },
    /* @__PURE__ */ React.createElement(
      Link,
      {
        to: "/",
        className: `logo ${mobileMenuIsOpen ? "-expanded" : ""}`
      },
      /* @__PURE__ */ React.createElement(QLogo, null)
    ),
    /* @__PURE__ */ React.createElement(
      "nav",
      {
        className: `main-nav ${mobileMenuIsOpen ? "-expanded" : ""}`
      },
      /* @__PURE__ */ React.createElement("div", { className: "nav-link-container" }, /* @__PURE__ */ React.createElement(
        NavLink,
        {
          className: "nav-link",
          onClick: closeMobileMenu,
          to: "/jobs"
        },
        "My Jobs"
      )),
      /* @__PURE__ */ React.createElement("div", { className: "nav-link-container" }, /* @__PURE__ */ React.createElement(
        NavLink,
        {
          className: "nav-link",
          onClick: closeMobileMenu,
          to: "/backends"
        },
        "Backends"
      )),
      /* @__PURE__ */ React.createElement("div", { className: "nav-link-container" }, /* @__PURE__ */ React.createElement(
        NavLink,
        {
          className: "nav-link",
          onClick: closeMobileMenu,
          to: "/projects"
        },
        "Projects"
      )),
      /* @__PURE__ */ React.createElement(
        HeaderExtendedMenu,
        {
          closeMobileMenu
        }
      )
    ),
    /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `mobile-menu-toggle ${mobileMenuIsOpen ? "-close" : ""}`,
        onClick: toggleMobileMenu
      },
      /* @__PURE__ */ React.createElement("span", { className: "lines" })
    )
  )));
};
