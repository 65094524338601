import { MdContentCopy } from "react-icons/md";
import copy from "copy-to-clipboard";
import { IonToast } from "../IonToast/index.js";
import { Icon } from "@chakra-ui/icons";
import React from "react";
export const IonTableCopyIconWithToast = ({ cell, toast, toastIdRef }) => /* @__PURE__ */ React.createElement(
  Icon,
  {
    "data-testid": "Copy-Icon",
    as: MdContentCopy,
    ml: 1,
    color: "gray.600",
    onClick: (e) => {
      e.stopPropagation();
      copy(cell.column.columnDef.cell(cell.getContext()));
      toastIdRef.current = toast({
        duration: 5e3,
        position: "bottom-left",
        render: () => /* @__PURE__ */ React.createElement(
          IonToast,
          {
            message: "Copied to clipboard",
            status: "success",
            toast,
            toastIdRef
          }
        )
      });
    }
  }
);
