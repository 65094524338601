import { Td } from "@chakra-ui/react";
import { flexRender } from "@tanstack/react-table";
import { IonTableCopyIconWithToast } from "./IonTableCopyIconWithToast.js";
import React from "react";
export const rowVisibleCellsMapFunction = ({
  cell,
  toast,
  toastIdRef,
  isRowSelectEnabled,
  cellIndex
}) => {
  const meta = cell.column.columnDef.meta;
  const { getDisplay, isCopyable, shouldRightAlign } = meta || {};
  return /* @__PURE__ */ React.createElement(
    Td,
    {
      whiteSpace: "break-spaces",
      key: cell.id,
      padding: !isRowSelectEnabled && cellIndex === 0 ? "0 24px" : "0 12px",
      isNumeric: shouldRightAlign,
      w: isCopyable ? "105px" : "auto",
      minW: isCopyable ? "105px" : "auto"
    },
    flexRender(
      getDisplay(cell.column.columnDef.cell(cell.getContext())),
      cell.getContext()
    ),
    isCopyable && /* @__PURE__ */ React.createElement(
      IonTableCopyIconWithToast,
      {
        cell,
        toast,
        toastIdRef
      }
    )
  );
};
