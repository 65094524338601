import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ProjectDetailWithContext } from "./ProjectDetail";
import { ProjectsList } from "./ProjectList";
import { ArchiveProjectModal } from "./modals/ArchiveProjectModal";
import { UnarchiveProjectModal } from "./modals/UnarchiveProjectModal";
export const Projects = ({ isLoadingLdContext }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if (isLoadingLdContext) {
    return null;
  }
  const values = pathname.split("/").filter(Boolean);
  if (values.pop() === "jobs" && values.length === 3) {
    navigate(values[1]);
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ArchiveProjectModal, null), /* @__PURE__ */ React.createElement(UnarchiveProjectModal, null), /* @__PURE__ */ React.createElement(Routes, null, /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/", element: /* @__PURE__ */ React.createElement(ProjectsList, null) }), /* @__PURE__ */ React.createElement(Route, { exact: true, path: "/*", element: /* @__PURE__ */ React.createElement(ProjectDetailWithContext, null) })));
};
