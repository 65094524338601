import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import { IonPanelHeading } from "../shared/IonPanelHeading";
import { IonDivider } from "../shared/IonDivider";
import { IonPanel } from "../shared/IonPanel";
import { useOrganization } from "../shared/Organization/useOrganization";
import * as Yup from "yup";
import { Form, Formik } from "../FormikWrapper";
import { IonToast } from "../shared/IonToast";
import api from "../../api.js";
import { useApi } from "../../Api/ApiProvider";
import { getNumberFromUsdFormattedString } from "../Projects/modals/ChangeProjectBudgetModal.js";
import { getSortedFriendlyTargets } from "../../util/qpu";
import { NumericFormat } from "react-number-format";
export const OrganizationSettings = () => {
  const toast = useToast();
  const toastIdRef = React.useRef();
  const { organization, refetch: refetchOrg } = useOrganization();
  const { Api: NewApi } = useApi();
  return /* @__PURE__ */ React.createElement(Box, { mb: "24px" }, /* @__PURE__ */ React.createElement(Text, { lineHeight: "38px", textStyle: "heading-3", mb: "12px" }, "Organization Settings"), /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(IonPanelHeading, null, "Basic information"), /* @__PURE__ */ React.createElement(IonDivider, null), /* @__PURE__ */ React.createElement(
    Formik,
    {
      enableReinitialize: true,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (values.orgId && values.name) {
          await api.updateOrganization(organization?.id, {
            name: values.name
          });
          toastIdRef.current = toast({
            duration: 5e3,
            position: "bottom-left",
            render: () => /* @__PURE__ */ React.createElement(
              IonToast,
              {
                message: "Your organization information has been successfully updated.",
                status: "success",
                toast,
                toastIdRef
              }
            )
          });
        }
        await refetchOrg();
        setSubmitting(false);
        resetForm({ values });
      },
      initialValues: {
        orgId: organization?.id || "",
        name: organization?.name || ""
      },
      validationSchema: Yup.object().shape({
        orgId: Yup.string().required("orgId is required"),
        name: Yup.string().required("name is required")
      })
    },
    ({
      touched,
      dirty,
      setErrors,
      errors,
      values,
      isSubmitting,
      handleBlur,
      handleChange
    }) => /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mb: "4px" }, "Organization name"), /* @__PURE__ */ React.createElement(
      Input,
      {
        id: "name",
        onFocus: () => {
          setErrors({ ...errors, name: "" });
        },
        type: "text",
        name: "name",
        value: values.name,
        onBlur: handleBlur,
        onChange: handleChange,
        w: "400px"
      }
    ), touched.name && errors.name && /* @__PURE__ */ React.createElement(Text, { color: "uiRed" }, errors.name)), /* @__PURE__ */ React.createElement(Box, { mt: "8px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mb: "4px" }, "Organization ID"), /* @__PURE__ */ React.createElement(
      Input,
      {
        readOnly: true,
        isDisabled: true,
        id: "orgId",
        type: "text",
        name: "orgId",
        value: values.orgId,
        w: "400px"
      }
    ), touched.orgId && errors.orgId && /* @__PURE__ */ React.createElement(Text, { color: "uiRed" }, errors.orgId)), /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "blue",
        w: "400px",
        size: "lg",
        mt: "24px",
        type: "submit",
        isDisabled: !dirty || isSubmitting
      },
      `${isSubmitting ? "Updating" : "Update"} organization information`
    ))
  )), /* @__PURE__ */ React.createElement(Box, { mt: "24px" }, /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      p: "24px",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px"
    },
    /* @__PURE__ */ React.createElement(IonPanelHeading, null, "Default project budget"),
    /* @__PURE__ */ React.createElement(IonDivider, null),
    /* @__PURE__ */ React.createElement(
      Formik,
      {
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
          const { orgId, radio } = values;
          setSubmitting(true);
          const defaultProjectBudget = radio === "1" ? null : getNumberFromUsdFormattedString(
            values.defaultProjectBudget
          );
          if (orgId && radio) {
            await NewApi.patchOrgSettings({
              orgId,
              settings: {
                defaultProjectBudget
              }
            });
            toastIdRef.current = toast({
              duration: 5e3,
              position: "bottom-left",
              render: () => /* @__PURE__ */ React.createElement(
                IonToast,
                {
                  message: "Your organization information has been successfully updated.",
                  status: "success",
                  toast,
                  toastIdRef
                }
              )
            });
          }
          await refetchOrg();
          setSubmitting(false);
          resetForm({ values });
        },
        initialValues: {
          orgId: organization?.id || "",
          defaultProjectBudget: organization?.settings?.defaultProjectBudget,
          radio: (organization?.settings?.defaultProjectBudget ?? null) === null ? "1" : "2"
        },
        validationSchema: Yup.object().shape({
          orgId: Yup.string().required("orgId is required"),
          defaultProjectBudget: Yup.string(),
          radio: Yup.string().oneOf(["1", "2"])
        })
      },
      ({
        dirty,
        values,
        isSubmitting,
        handleBlur,
        handleChange
      }) => /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(Box, { maxW: "500px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "8px" }, "Set the project default budget. This will be applied to the personal projects created by default when member joins your organization.", /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Note:"), " This setting won\u2019t be applied to the existing projects."), /* @__PURE__ */ React.createElement(
        RadioGroup,
        {
          name: "radio",
          value: values.radio
        },
        /* @__PURE__ */ React.createElement(Stack, null, /* @__PURE__ */ React.createElement(
          Radio,
          {
            size: "md",
            value: "1",
            onChange: handleChange,
            onBlur: handleBlur
          },
          /* @__PURE__ */ React.createElement(
            Text,
            {
              textStyle: "body-3",
              mb: "4px"
            },
            "Unlimited"
          )
        ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "16px" }, "Do not set a budget limit for this project. Users can submit QPU jobs as long as the organization has remaining budget."), /* @__PURE__ */ React.createElement(
          Radio,
          {
            size: "md",
            value: "2",
            onChange: handleChange,
            onBlur: handleBlur
          },
          /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Limited")
        ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "8px" }, "Users can run jobs until the limit is hit for the project.", /* @__PURE__ */ React.createElement(
          Text,
          {
            ml: "0.25rem",
            fontStyle: "italic",
            as: "span"
          },
          "Note: a budget of $0.00 translates to no available budget, meaning QPU jobs cannot be run."
        )))
      ), /* @__PURE__ */ React.createElement(InputGroup, null, /* @__PURE__ */ React.createElement(
        InputLeftAddon,
        {
          bg: values.radio === "1" ? "white" : "gray.50",
          borderColor: values.radio === "1" ? "gray.100" : "gray.200",
          color: values.radio === "1" ? "gray.400" : "gray.600"
        },
        "$"
      ), /* @__PURE__ */ React.createElement(
        NumericFormat,
        {
          bg: values.radio === "1" ? "gray.50" : "white",
          customInput: Input,
          fixedDecimalScale: true,
          thousandSeparator: true,
          allowLeadingZeros: true,
          decimalScale: 2,
          textStyle: "body-3",
          isDisabled: values.radio === "1",
          name: "defaultProjectBudget",
          onChange: handleChange,
          onBlur: handleBlur,
          value: values.defaultProjectBudget,
          errorBorderColor: "uiRed",
          min: 0,
          color: values.radio === "1" ? "gray.500" : "gray.600",
          maxWidth: "356px",
          type: "string",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderColor: "gray.200"
        }
      )), /* @__PURE__ */ React.createElement(
        Button,
        {
          variant: "blue",
          maxW: "400px",
          size: "lg",
          mt: "24px",
          type: "submit",
          isDisabled: !dirty || isSubmitting
        },
        `${isSubmitting ? "Updating" : "Update"} default project budget`
      )))
    )
  )), /* @__PURE__ */ React.createElement(Box, { mt: "24px" }, /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(IonPanelHeading, null, "Backend access for new projects"), /* @__PURE__ */ React.createElement(IonDivider, null), /* @__PURE__ */ React.createElement(
    Formik,
    {
      enableReinitialize: true,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        const { orgId, defaultProjectTargets = {} } = values;
        setSubmitting(true);
        if (orgId) {
          const transformedTargets = Object.entries(
            defaultProjectTargets
          ).filter(([, v]) => v).map(([k]) => k);
          await NewApi.patchOrgSettings({
            orgId,
            settings: {
              defaultProjectTargets: transformedTargets
            }
          });
          toastIdRef.current = toast({
            duration: 5e3,
            position: "bottom-left",
            render: () => /* @__PURE__ */ React.createElement(
              IonToast,
              {
                message: "Your organization information has been successfully updated.",
                status: "success",
                toast,
                toastIdRef
              }
            )
          });
        }
        await refetchOrg();
        setSubmitting(false);
        resetForm({ values });
      },
      initialValues: {
        orgId: organization?.id || "",
        defaultProjectTargets: (organization?.settings?.allowExtraTargets || []).reduce((map, val) => {
          map[val] = (organization?.settings?.defaultProjectTargets || []).includes(val);
          return map;
        }, {})
      },
      validationSchema: Yup.object().shape({})
    },
    ({
      dirty,
      values,
      isSubmitting,
      handleBlur,
      handleChange,
      setValues
    }) => /* @__PURE__ */ React.createElement(Form, null, /* @__PURE__ */ React.createElement(Box, { maxW: "500px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mb: "4px" }, "Choose which backends users can submit jobs to across the newly created projects.", /* @__PURE__ */ React.createElement("br", null), " ", /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("b", null, "Note:"), " If you wish to make custom backends selection to one of your projects please head to the project settings page."), /* @__PURE__ */ React.createElement(InputGroup, null, /* @__PURE__ */ React.createElement(Stack, null, getSortedFriendlyTargets(
      Object.keys(
        values.defaultProjectTargets
      )
    ).map((target) => {
      return /* @__PURE__ */ React.createElement(
        Checkbox,
        {
          key: target.target,
          name: target.name,
          colorScheme: "blue",
          size: "sm",
          isDisabled: target.target === "simulator",
          color: target.target === "simulator" ? "gray.500" : "gray.600",
          onChange: (e) => {
            const defaultProjectTargets = {
              ...values.defaultProjectTargets,
              [target.target]: e.target.checked
            };
            setValues({
              ...values,
              defaultProjectTargets
            });
            handleChange(e);
          },
          onBlur: handleBlur,
          isChecked: target.target === "simulator" || values.defaultProjectTargets?.[target.target]
        },
        target.name
      );
    }))), /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "blue",
        maxW: "400px",
        size: "lg",
        mt: "24px",
        type: "submit",
        isDisabled: !dirty || isSubmitting
      },
      `${isSubmitting ? "Updating" : "Update"} backends access`
    )))
  ))));
};
