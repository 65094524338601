import { IdText } from "../components/shared/IdText/index.js";
import React from "react";
export const JOB_DETAIL_TABLE_KEY_LABELS = [
  {
    key: "request",
    label: "Created",
    isSortable: true
  },
  {
    key: "status",
    label: "Status"
  },
  { key: "name", label: "Name" },
  {
    key: "id",
    label: "ID",
    isCopyable: true,
    getDisplay: (id) => /* @__PURE__ */ React.createElement(IdText, { id })
  },
  { key: "type", label: "Type" },
  { key: "circuits", label: "Circuits" },
  { key: "action", label: "" }
];
