import React, { useEffect, useState } from "react";
import {
  Route,
  useLocation,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import PrivateRoute from "../CustomRouting/PrivateRoute";
import { Header } from "../Header/Header";
import { Settings } from "../Settings/Settings";
import { NotFound } from "../NotFound/NotFound";
import { pageAnalytics } from "../../util/analytics";
import { TimeoutModal } from "../TimeoutModal/TimeoutModal";
import { CancelJobConfirmationModal } from "../CancelJobConfirmationModal";
import { ModalProvider } from "../shared/baseModals/ModalProvider";
import { Projects } from "../Projects";
import { OrganizationContextProvider } from "../shared/Organization/provider";
import { StatusContextProvider } from "../shared/Status/provider";
import { Systems } from "../Systems";
import { useMe } from "../shared/Me/useMe";
import "./App.css";
import { MyContextProvider } from "../shared/Me/provider";
import { Jobs } from "../Jobs";
import { ApiProvider } from "../../Api/ApiProvider";
import { CancelMultipleJobsConfirmationModal } from "../CancelMultipleJobsConfirmationModal/index.js";
import { MyJobsProvider } from "../../contexts/MyJobsContext.js";
import { ProjectJobsProvider } from "../../contexts/ProjectJobsContext.js";
const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } = window._dynamic_env_;
if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1
  });
}
export const App = () => {
  const [isLoadingLdContext, setIsLoadingLdContext] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userData, isLoading } = useMe();
  const ldClient = useLDClient();
  useEffect(() => {
    pageAnalytics("route-change");
  }, [location]);
  useEffect(() => {
    if (isLoading || !ldClient || !userData) return;
    const ldContext = {
      kind: "user",
      key: userData.id,
      id: userData.id,
      email: userData.email,
      role: userData.role,
      orgId: userData.organization,
      partnerId: userData.partnerId
    };
    ldClient.identify(ldContext, null, () => {
      setIsLoadingLdContext(false);
    });
  }, [ldClient, userData, isLoading]);
  if (location.pathname.startsWith("/auth/callback")) {
    const params = new URLSearchParams(location.search);
    const path = params.get("target_uri") || "/jobs";
    navigate(path);
  }
  return /* @__PURE__ */ React.createElement(MyContextProvider, null, /* @__PURE__ */ React.createElement(ApiProvider, null, /* @__PURE__ */ React.createElement(OrganizationContextProvider, null, /* @__PURE__ */ React.createElement(StatusContextProvider, null, /* @__PURE__ */ React.createElement(MyJobsProvider, null, /* @__PURE__ */ React.createElement(ProjectJobsProvider, null, /* @__PURE__ */ React.createElement(ModalProvider, null, /* @__PURE__ */ React.createElement(CancelJobConfirmationModal, null), /* @__PURE__ */ React.createElement(CancelMultipleJobsConfirmationModal, null), /* @__PURE__ */ React.createElement(TimeoutModal, { location }), /* @__PURE__ */ React.createElement(Header, null), /* @__PURE__ */ React.createElement(Routes, null, /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "jobs/*",
      element: /* @__PURE__ */ React.createElement(
        PrivateRoute,
        {
          component: Jobs
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "backends/*",
      element: /* @__PURE__ */ React.createElement(
        PrivateRoute,
        {
          component: Systems
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "settings/*",
      element: /* @__PURE__ */ React.createElement(
        PrivateRoute,
        {
          component: Settings
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/organization/:id/*",
      element: /* @__PURE__ */ React.createElement(
        PrivateRoute,
        {
          component: Settings
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "projects/*",
      element: /* @__PURE__ */ React.createElement(
        PrivateRoute,
        {
          component: Projects,
          isLoadingLdContext
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/",
      element: /* @__PURE__ */ React.createElement(
        Navigate,
        {
          to: "/jobs",
          replace: true
        }
      )
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/not-found",
      element: /* @__PURE__ */ React.createElement(NotFound, null)
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/404",
      element: /* @__PURE__ */ React.createElement(NotFound, null)
    }
  ), /* @__PURE__ */ React.createElement(Route, { component: NotFound })))))))));
};
