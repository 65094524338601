import { Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
export const PanelRow = ({ label, value }) => /* @__PURE__ */ React.createElement(Flex, { m: "8px 0", _notFirst: { mt: "10px" } }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
  Text,
  {
    as: "span",
    ml: "8px",
    textStyle: "body-1",
    color: "gray.600",
    w: "170px"
  },
  label
), /* @__PURE__ */ React.createElement(
  Text,
  {
    as: "span",
    textStyle: "body-8",
    color: value ? "gray.800" : "gray.400",
    maxWidth: "700px",
    wordBreak: "break-all"
  },
  value
)));
