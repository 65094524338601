import React, { useState } from "react";
import "./IonVisualizer.css";
import { IonVisualizerSVG } from "./IonVisualizerSVG/IonVisualizerSVG";
import { IonVisualizerSettings } from "./IonVisualizerSettings";
import { Circuit } from "@ionq/circuit";
const updateCircuitForVisualizer = (circuit) => {
  let ops = [];
  circuit.ops.forEach((op) => {
    if (!op.alloc) ops.push([op]);
  });
  circuit.ops = ops;
  circuit.qubits.map((qubit) => {
    delete qubit.circuit;
    return qubit;
  });
  return circuit;
};
const prepareCircuitForVisualizer = (script) => {
  let circuit;
  if (script?.body) {
    try {
      if (script.lang === "json") {
        circuit = typeof script.body === "string" ? JSON.parse(script.body.circuit) || [] : script.body.circuit || [];
        let qubits = circuit.reduce((acc, cv = {}) => {
          let targets = cv.target !== void 0 ? cv.target : cv.targets || [];
          let controls = cv.control !== void 0 ? cv.control : cv.controls || [];
          targets = targets instanceof Array ? targets : [targets];
          controls = controls instanceof Array ? controls : [controls];
          acc = Math.max(acc, ...targets, ...controls);
          return acc;
        }, 0);
        qubits += 1;
        circuit = Circuit.fromJSON({ circuit, qubits });
        circuit = updateCircuitForVisualizer(circuit);
      }
      return circuit;
    } catch (error) {
      console.error(error);
    }
  }
};
export const IonVisualizer = (props) => {
  const [zoom, setZoom] = useState(1);
  const [orientation, setVisualizerOrientation] = useState("horizontal");
  const svgRef = React.createRef();
  const { visualizerHeight, title, code, language, job, script, type } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "Visualizer" }, /* @__PURE__ */ React.createElement("div", { className: "visualization-scrollshadows" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "visualization-container",
      style: { height: visualizerHeight }
    },
    /* @__PURE__ */ React.createElement(
      IonVisualizerSVG,
      {
        type,
        svgRef,
        circuit: prepareCircuitForVisualizer(script),
        code: JSON.stringify(code.circuit),
        language,
        job,
        zoom,
        isHorizontal: orientation === "horizontal"
      }
    )
  )), /* @__PURE__ */ React.createElement(
    IonVisualizerSettings,
    {
      svgRef,
      svgName: title,
      zoom,
      isHorizontal: orientation === "horizontal",
      setZoom,
      setVisualizerOrientation,
      code,
      circuit: code.circuit
    }
  ));
};
