import React, { useState } from "react";
import { Histogram } from "../Histogram";
import AceEditor from "react-ace";
import { HistogramSettings } from "../Histogram/HistogramSettings";
import "./JobResults.css";
export default function JobResults(props) {
  const { job, results } = props;
  const [viewMode, setViewMode] = useState("histogram");
  const prettyPrintJSON = (raw) => {
    const stringified = JSON.stringify(raw, null, 4);
    return stringified.substring(2, stringified.length - 2);
  };
  return /* @__PURE__ */ React.createElement("div", { className: "JobResults" }, viewMode === "histogram" && /* @__PURE__ */ React.createElement(
    Histogram,
    {
      jobID: job.id,
      jobName: job.name,
      histogram: results,
      qubitCount: job.qubits,
      shotCount: job.shots
    }
  ), viewMode === "JSON" && /* @__PURE__ */ React.createElement("div", { className: "json-view-container" }, /* @__PURE__ */ React.createElement(
    AceEditor,
    {
      setOptions: { useWorker: false },
      mode: "json",
      theme: "ionq",
      readOnly: true,
      showGutter: false,
      showPrintMargin: false,
      fontSize: 16,
      lineHeight: 22,
      name: "JSONRawView",
      value: prettyPrintJSON(results),
      wrapEnabled: true,
      maxLines: 12,
      style: {
        lineHeight: "1.625",
        width: "100%",
        height: "100%",
        fontFamily: "Ubuntu-Mono"
        //apparently necessary so it calculates sizes correctly on first load
      }
    }
  )), /* @__PURE__ */ React.createElement(
    HistogramSettings,
    {
      jobID: job.id,
      jobName: job.name,
      histogram: results,
      viewMode,
      onViewModeChange: setViewMode
    }
  ));
}
