export const getColumns = ({ columnHelper, keyLabels }) => keyLabels.map(
  ({ key, label, isCopyable, isSortable, info, getDisplay }) => columnHelper.accessor(key, {
    cell: (d) => d.getValue(),
    header: label,
    meta: {
      /*
      getDisplay is for when you need to display a different value than the raw value
      e.g. we use it for is showing a truncated id while still allowing the whole id to be copied
      otherwise it should return the raw value if not provided
      getDisplay: (id) => <IdText id={id} />
      */
      getDisplay: getDisplay ?? ((v) => v),
      isCopyable: isCopyable ?? false,
      isSortable: isSortable ?? false,
      info,
      shouldRightAlign: label === ""
    }
  })
);
