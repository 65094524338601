import React from "react";
import {
  Box,
  Center,
  Flex,
  Progress,
  Spinner,
  Badge,
  Text,
  VStack
} from "@chakra-ui/react";
import { useOrganization } from "../shared/Organization/useOrganization";
import { useOrgQuotas } from "../Projects/hooks/useOrgQuotas";
import { IonTable } from "../shared/IonTable/index.js";
import { MdOutlineSearch } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FEATURE_FLAG } from "../App/featureFlags.js";
const ORGANIZATION_USAGE_COLUMNS = [
  {
    key: "credit-value",
    label: "Credit value"
  },
  {
    key: "consumed",
    label: "Consumed"
  },
  { key: "remaining", label: "Remaining" }
];
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  trailingZeroDisplay: "stripIfInteger"
});
export const UsageSettings = () => {
  const flags = useFlags();
  const showBillingEngine = flags[FEATURE_FLAG.BILLING_ENGINE_MVP];
  const { organization, isLoading: orgIsLoading, error } = useOrganization();
  const { orgQuotas, isLoading: quotasIsLoading } = useOrgQuotas();
  const { credit_remaining_usd, credit_used_last_30_days_usd, credits } = orgQuotas;
  const rows = (credits || []).map((row, index) => {
    return {
      id: index,
      "credit-value": row?.backends ? /* @__PURE__ */ React.createElement(Box, { p: "16px 0" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2" }, formatter.format(row.credit_usd)), /* @__PURE__ */ React.createElement(Flex, { mt: "8px", mb: "8px" }, row?.backends?.map((name) => /* @__PURE__ */ React.createElement(
        Badge,
        {
          key: name,
          size: "xs",
          variant: "outline",
          mr: "8px",
          h: "20px",
          color: "gray.700",
          lineHeight: "1rem",
          textTransform: "none"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5" }, name)
      ))), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5" }, row?.from_date?.split("T").shift(), " \u2013", /* @__PURE__ */ React.createElement(Text, { as: "span", ml: "0.25rem" }, row?.to_date?.split("T").shift() || "Present"))) : null,
      consumed: row?.backends ? /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5" }, formatter.format(row.credit_used_usd)), /* @__PURE__ */ React.createElement(
        Progress,
        {
          variant: "green",
          h: "4px",
          mt: "4px",
          color: "red.500",
          borderRadius: "3px",
          value: Number(row.credit_used_usd) >= Number(row.credit_usd) ? 100 : row.credit_used_usd && row.credit_usd ? Number(row.credit_used_usd) / Number(row.credit_usd) * 100 : 0
        }
      )) : null,
      remaining: row?.backends ? /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5" }, formatter.format(
        Number(row.credit_used_usd) > Number(row.credit_usd) ? 100 : Number(row.credit_usd) - Number(row.credit_used_usd)
      )) : null
    };
  });
  if (orgIsLoading || quotasIsLoading) {
    return /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Spinner, null));
  }
  if (!showBillingEngine) {
    return null;
  }
  if (error) {
    return /* @__PURE__ */ React.createElement(Text, null, error);
  }
  const hasAccessToPaidBackend = organization?.hasAccessToPaidBackend;
  return /* @__PURE__ */ React.createElement(Box, { mb: "10px" }, /* @__PURE__ */ React.createElement(Text, { lineHeight: "38px", textStyle: "heading-3", mb: "12px" }, "Organization Spend"), /* @__PURE__ */ React.createElement(Box, { mb: "24px" }, /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      p: "24px",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px"
    },
    /* @__PURE__ */ React.createElement(Flex, { gap: "150px" }, /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5" }, "Credit remaining"), /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-2", mt: "8px" }, formatter.format(credit_remaining_usd))), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5" }, "Used in last 30 days"), /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-2", mt: "8px" }, formatter.format(credit_used_last_30_days_usd))))
  ), hasAccessToPaidBackend && /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6", color: "gray.600", mt: "8px" }, "For more details or to add credit to your account, please contact your IonQ representative")), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5" }, "Credits"), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
    IonTable,
    {
      rows,
      label: "Credit",
      keyLabels: ORGANIZATION_USAGE_COLUMNS,
      emptyView: /* @__PURE__ */ React.createElement(Box, { p: "40px" }, /* @__PURE__ */ React.createElement(VStack, null, /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlineSearch,
          w: "24px",
          h: "24px"
        }
      ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", mt: "8px" }, "No active or past credits are found for this organization")))
    }
  ))));
};
