import { useEffect, useState } from "react";
import api from "../../api";
const fetchJob = async ({ jobId, onSuccess, onError }) => {
  try {
    const res = await api.getJob(jobId);
    onSuccess(res);
  } catch (e) {
    onError(e);
  }
};
export const useJob = (jobId) => {
  const [job, setJob] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!jobId) return;
    setLoading(true);
    fetchJob({
      jobId,
      onSuccess: (res) => {
        setJob(res);
        setLoading(false);
        setError(null);
      },
      onError: () => {
        setError("error retrieving job");
        setLoading(false);
      }
    });
  }, [jobId]);
  return { loading, job, error };
};
