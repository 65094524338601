import React from "react";
import { Box, Text } from "@chakra-ui/react";
import {
  getIonDateFormat,
  getTooltipFormattedDate,
  USD
} from "../../util/format.js";
import { IonTooltip } from "./IonTooltip/index.js";
import { Icon } from "@chakra-ui/icons";
import { MdGrain } from "react-icons/md";
import { JobStatusTag } from "./JobStatusTag.js";
import { NameCell } from "./IonTableCells/NameCell.js";
import { JobTag } from "../Jobs/JobTag.js";
export const detailColumns = ({ row }) => {
  return {
    request: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", noOfLines: 1, w: "195px" }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
      IonTooltip,
      {
        label: `${getTooltipFormattedDate(row.request)} ago`
      },
      getIonDateFormat(row.request)
    ))),
    name: /* @__PURE__ */ React.createElement(
      NameCell,
      {
        name: row.name,
        width: "301px",
        errorMitigation: row?.error_mitigation?.debias || false
      }
    ),
    circuits: /* @__PURE__ */ React.createElement(
      Text,
      {
        maxW: "77px",
        textStyle: "body-8",
        color: row.circuits ? "ionDark" : "gray.500"
      },
      row.circuits || "N/A"
    ),
    id: row.id,
    status: /* @__PURE__ */ React.createElement(Box, { w: "95px" }, /* @__PURE__ */ React.createElement(JobStatusTag, { status: row.status })),
    statusRaw: row.status
  };
};
export const sharedColumns = ({
  row,
  hasAccessToPaidBackend,
  noiseModelName
}) => {
  return {
    ...detailColumns({ row }),
    type: /* @__PURE__ */ React.createElement(Box, { maxW: "118px" }, /* @__PURE__ */ React.createElement(JobTag, { id: row.id, type: row.type, circuits: row.circuits })),
    usage: row?.cost_usd === null ? null : /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", maxW: "120px" }, hasAccessToPaidBackend ? USD.format(row?.cost_usd ?? 0) : "$0.00"),
    target: /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-8",
        color: "ionDark",
        w: "90px",
        noOfLines: 1,
        isTruncated: false
      },
      row.target.split("qpu.").pop(),
      row.noise && row.noise.model && row.noise.model !== "ideal" ? /* @__PURE__ */ React.createElement(IonTooltip, { label: noiseModelName }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
        Icon,
        {
          boxSize: "18px",
          as: MdGrain,
          ml: "4px",
          color: "gray.600",
          verticalAlign: "sub"
        }
      ))) : null
    )
  };
};
