import React from "react";
import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal
} from "@chakra-ui/react";
import { ProjectProvider, useProject } from "./hooks/useProject";
import { ProjectJobs } from "./ProjectJobs";
import { ProjectMembers } from "./ProjectMembers";
import { IonTabs } from "../shared/IonTabs";
import { PageContainer } from "../shared/PageContainer";
import { ProjectSettings } from "./ProjectSettings";
import { useIsOwner } from "../shared/Me/useMe";
import { ChangeProjectBudgetModal } from "./modals/ChangeProjectBudgetModal";
import { ArchivedBadge } from "./ArchivedBadge";
import { useProjects } from "./hooks/useProjects";
import { ProjectsHeading } from "./ProjectsHeading.js";
import { Link, useLocation } from "react-router-dom";
const OWNER_TABS = [
  { name: "Jobs", Component: ProjectJobs },
  { name: "Members", Component: ProjectMembers },
  { name: "Settings", Component: ProjectSettings }
];
const MEMBER_TABS = [
  { name: "Jobs", Component: ProjectJobs },
  { name: "Members", Component: ProjectMembers }
];
const ProjectDetail = () => {
  const { pathname } = useLocation();
  const [, , id] = pathname.split("/");
  const { project, error, isLoading } = useProject();
  const isOwner = useIsOwner();
  const { projects } = useProjects();
  const isProjectInUserProjects = (projects || []).map((p) => p.id).filter(Boolean).includes(id);
  if (projects.length && !isProjectInUserProjects) {
    return /* @__PURE__ */ React.createElement(PageContainer, null, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
      Modal,
      {
        closeOnOverlayClick: false,
        isOpen: true,
        onClose: () => {
        },
        size: "xl",
        isCentered: true
      },
      /* @__PURE__ */ React.createElement(ModalOverlay, null),
      /* @__PURE__ */ React.createElement(ModalContent, { p: "24px 32px 32px" }, /* @__PURE__ */ React.createElement(ModalHeader, { p: 0 }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-1" }, "Your project access is no longer valid")), /* @__PURE__ */ React.createElement(ModalBody, { p: "24px 0" }, "Your access to this project is no longer valid or the project doesn\u2019t exist anymore. Please contact your organization administrator for support."), /* @__PURE__ */ React.createElement(ModalFooter, { p: 0 }, /* @__PURE__ */ React.createElement(Button, { size: "lg", variant: "blue", ml: "12px" }, /* @__PURE__ */ React.createElement(Link, { to: "/projects" }, "Continue to projects"))))
    )));
  }
  if (isLoading) return /* @__PURE__ */ React.createElement(PageContainer, null, "Loading\u2026");
  if (error) return /* @__PURE__ */ React.createElement(PageContainer, null, error);
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "gray.50",
      h: ["110px", null, "118px", null, "130px"],
      gap: "10px",
      borderBottom: "1px solid #E0E0E3"
    },
    /* @__PURE__ */ React.createElement(ChangeProjectBudgetModal, null),
    /* @__PURE__ */ React.createElement(PageContainer, null, /* @__PURE__ */ React.createElement(HStack, { mb: "29px" }, /* @__PURE__ */ React.createElement(ProjectsHeading, { projectName: project.name }), project.isArchived && /* @__PURE__ */ React.createElement(ArchivedBadge, null)), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
      IonTabs,
      {
        baseUrl: `/projects/${project.id}`,
        tabs: isOwner ? OWNER_TABS : MEMBER_TABS
      }
    )))
  );
};
export const ProjectDetailWithContext = () => {
  const { pathname } = useLocation();
  const [, , id] = pathname.split("/");
  return /* @__PURE__ */ React.createElement(ProjectProvider, { id }, /* @__PURE__ */ React.createElement(ProjectDetail, null));
};
