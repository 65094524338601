import React, { useMemo, useRef } from "react";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Spacer,
  Spinner,
  Text,
  useClipboard,
  useToast
} from "@chakra-ui/react";
import { PageContainer } from "../shared/PageContainer/index.js";
import { IonPanel } from "../shared/IonPanel/index.js";
import UnknownCircuitOutput from "./JobUnknownCircuitOutput.js";
import JobResults from "./JobResults.js";
import AceEditor from "react-ace";
import Console from "../Console/Console.js";
import { useJob } from "../CancelJobConfirmationModal/useJob.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useJobProject } from "../Projects/hooks/useJobProject.js";
import { useJobResults } from "../Projects/hooks/useJobResults.js";
import { useJobProgram } from "../Projects/hooks/useJobProgram.js";
import { IonVisualizer } from "../IonVisualizer/IonVisualizer.js";
import {
  getIonDateFormat,
  getTooltipFormattedDate,
  MillisecondTime,
  USD
} from "../../util/format.js";
import { JobStatusTag } from "../shared/JobStatusTag.js";
import { JobDetailResultsPopover } from "./JobDetailResultsPopover.js";
import downloadCode from "../../lib/downloadCode.js";
import { IonToast } from "../shared/IonToast/index.js";
import { IonTooltip } from "../shared/IonTooltip/index.js";
import { useJobs } from "../../hooks/useJobs.js";
import { DefaultErrorView } from "../shared/IonTable/DefaultErrorView.js";
import { JobsEmptyView } from "../shared/JobsEmptyView.js";
import { detailColumns } from "../shared/sharedColumns.js";
import { JobsRowActionPopover } from "../shared/JobsRowActionPopover.js";
import { JOB_DETAIL_TABLE_KEY_LABELS } from "../../util/jobDetailTableHelpers.js";
import { JobTag } from "./JobTag.js";
import { IonPaginatedTable } from "../shared/IonTable/IonPaginatedTable.js";
import { ProjectJobsContext } from "../../contexts/ProjectJobsContext.js";
import { useJobProgress } from "../Projects/hooks/useJobProgress.js";
import { PanelRow } from "./PanelRow.js";
export const JobDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = location.pathname.split("/").filter(Boolean).pop();
  const { job, isLoading: isJobLoading, error: jobError } = useJob(jobId);
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError
  } = useJobProject(job?.project_id);
  const {
    results,
    isLoading: isResultsLoading,
    error: resultsError
  } = useJobResults({ jobId, shouldFetchResults: job?.status === "completed" });
  const {
    program,
    isLoading: isProgramLoading,
    error: programError
  } = useJobProgram(jobId);
  const {
    jobs: childrenJobs,
    isLoading: isChildrenJobsLoading,
    error: childrenJobsError
  } = useJobs({
    filters: { parentId: jobId }
  });
  const isMyJobs = !!location?.state?.isMyJobs || false;
  const { progress: jobProgress } = useJobProgress({
    jobId
  });
  const toast = useToast();
  const toastIdRef = useRef();
  const { onCopy } = useClipboard(JSON.stringify(results));
  const rows = useMemo(() => {
    return childrenJobs.map((row) => {
      return {
        ...detailColumns({
          row
        }),
        ...{
          type: /* @__PURE__ */ React.createElement(
            JobTag,
            {
              type: row.type,
              id: row.id,
              circuits: row.circuits
            }
          ),
          onRowClick: () => navigate(`/jobs/${row.id}`, {
            state: { isMyJobs }
          }),
          action: /* @__PURE__ */ React.createElement(JobsRowActionPopover, { job: row })
        }
      };
    });
  }, [childrenJobs]);
  if (programError || jobError || resultsError || projectError || childrenJobsError) {
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", color: "uiRed" }, programError || jobError || resultsError || projectError || childrenJobsError));
  }
  if (isProgramLoading || isJobLoading || isResultsLoading || isProjectLoading || isChildrenJobsLoading) {
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, null));
  }
  const hasJsonResults = [
    "optimization",
    "optimization-iteration",
    "quantum-function"
  ].includes(job.type);
  const isSingleCircuit = job.type === "circuit" && (job.circuits === 1 || job.children?.length === 0);
  return /* @__PURE__ */ React.createElement(PageContainer, { p: "32px" }, /* @__PURE__ */ React.createElement(Flex, { mb: "32px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      color: "uiBlue",
      textStyle: "heading-3",
      fontWeight: 500,
      mr: "8px"
    },
    /* @__PURE__ */ React.createElement(Link, { to: isMyJobs ? "/jobs" : "/projects" }, isMyJobs ? "Jobs" : "Projects")
  ), !isMyJobs && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Text,
    {
      color: "gray.600",
      textStyle: "heading-3",
      mr: "8px",
      as: "span"
    },
    "/"
  ), /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "div",
      color: "uiBlue",
      textStyle: "heading-3",
      mr: "8px",
      isTruncated: true,
      maxW: "300px"
    },
    /* @__PURE__ */ React.createElement(Link, { to: `/projects/${project.id}` }, project.name || project.id)
  )), /* @__PURE__ */ React.createElement(Text, { color: "gray.600", textStyle: "heading-3", mr: "8px", as: "span" }, "/"), /* @__PURE__ */ React.createElement(
    Text,
    {
      color: "ionDark",
      textStyle: "heading-3",
      as: "div",
      isTruncated: true
    },
    job.name || job.id
  )), /* @__PURE__ */ React.createElement(Box, { mt: "24px", mb: "32px" }, /* @__PURE__ */ React.createElement(Flex, { w: "100%", mb: "12px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-5",
      mb: "8px",
      color: "gray.800",
      lineHeight: "38px"
    },
    "Job Summary"
  ), /* @__PURE__ */ React.createElement(Spacer, null), job.status !== "failed" && job.status !== "canceled" && /* @__PURE__ */ React.createElement(
    JobDetailResultsPopover,
    {
      menuItems: [
        {
          key: "download",
          node: "Download as JSON",
          onClick: () => {
            downloadCode(
              JSON.stringify(results),
              job.id || `${job.name}`,
              "json"
            );
          }
        },
        {
          key: "copy",
          node: "Copy to clipboard",
          onClick: () => {
            onCopy();
            toastIdRef.current = toast({
              duration: 5e3,
              position: "bottom-left",
              render: () => /* @__PURE__ */ React.createElement(
                IonToast,
                {
                  message: "Results copied to clipboard",
                  status: "success",
                  toast,
                  toastIdRef
                }
              )
            });
          }
        }
      ]
    }
  )), /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(PanelRow, { label: "Job Name", value: job.name }), /* @__PURE__ */ React.createElement(PanelRow, { label: "Job ID", value: job.id }), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Project Name",
      value: /* @__PURE__ */ React.createElement(Link, { to: `/projects/${project.id}` }, /* @__PURE__ */ React.createElement(Text, { color: "uiBlue" }, project.name))
    }
  ), /* @__PURE__ */ React.createElement(PanelRow, { label: "Project ID", value: project.id }), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Status",
      value: /* @__PURE__ */ React.createElement(JobStatusTag, { status: job.status })
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Backend",
      value: job.target.split("qpu.").pop()
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Type",
      value: /* @__PURE__ */ React.createElement(
        JobTag,
        {
          type: job.type,
          id: job.id,
          circuits: job.circuits
        }
      )
    }
  ), job.noise ? /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Noise Model",
      value: job.noise.model
    }
  ) : null, job.noise?.seed ? /* @__PURE__ */ React.createElement(PanelRow, { label: "Noise Seed", value: job.noise.seed }) : null, /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Circuits",
      value: job.circuits || "N/A"
    }
  ), /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Cost",
      value: USD.format(job.cost_usd ?? 0)
    }
  ), job.request && /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Requested",
      value: /* @__PURE__ */ React.createElement(
        IonTooltip,
        {
          label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
            job.request
          )} ago`)
        },
        getIonDateFormat(job.request)
      )
    }
  ), job.response && /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Completed",
      value: /* @__PURE__ */ React.createElement(
        IonTooltip,
        {
          label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
            job.response
          )} ago`)
        },
        getIonDateFormat(job.response)
      )
    }
  ), typeof job.execution_time === "number" && job.execution_time > 0 && /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Executed in",
      value: /* @__PURE__ */ React.createElement(
        MillisecondTime,
        {
          time: job.execution_time
        }
      )
    }
  ), job.predicted_execution_time && /* @__PURE__ */ React.createElement(
    PanelRow,
    {
      label: "Predicted",
      value: /* @__PURE__ */ React.createElement(
        MillisecondTime,
        {
          time: job.predicted_execution_time
        }
      )
    }
  ))), job.metadata && /* @__PURE__ */ React.createElement(Box, { mb: "32px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Metadata"), /* @__PURE__ */ React.createElement(IonPanel, null, Object.keys(job.metadata).map((key) => {
    return /* @__PURE__ */ React.createElement(
      PanelRow,
      {
        key,
        label: key,
        value: JSON.stringify(job.metadata[key])
      }
    );
  }))), jobProgress && job.type === "optimization" && /* @__PURE__ */ React.createElement(Box, { mb: "32px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Progress"), /* @__PURE__ */ React.createElement(Box, { bg: "white", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)" }, /* @__PURE__ */ React.createElement(
    Console,
    {
      removeBottomPadding: true,
      consoleData: jobProgress,
      viewModeExplicit: "JSON",
      consoleHeight: 250
    }
  ))), results && (isSingleCircuit || hasJsonResults) && /* @__PURE__ */ React.createElement(Box, { mb: "32px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Results"), hasJsonResults && /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)"
    },
    /* @__PURE__ */ React.createElement(
      Console,
      {
        removeBottomPadding: true,
        consoleData: results,
        viewModeExplicit: "JSON",
        consoleHeight: 250
      }
    )
  ), isSingleCircuit && /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px"
    },
    /* @__PURE__ */ React.createElement("div", null, !results && /* @__PURE__ */ React.createElement(UnknownCircuitOutput, { status: job.status }), results && /* @__PURE__ */ React.createElement(JobResults, { job, results }))
  )), job.children?.length > 0 && /* @__PURE__ */ React.createElement(Box, { mb: "32px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Children jobs"), /* @__PURE__ */ React.createElement(
    IonPaginatedTable,
    {
      context: ProjectJobsContext,
      emptyView: childrenJobsError ? /* @__PURE__ */ React.createElement(DefaultErrorView, { error: childrenJobsError }) : /* @__PURE__ */ React.createElement(JobsEmptyView, null),
      isRowSelectEnabled: false,
      keyLabels: JOB_DETAIL_TABLE_KEY_LABELS,
      label: "Job",
      onRowClick: (row) => row.onRowClick(),
      rows: childrenJobsError ? [] : rows
    }
  )), /* @__PURE__ */ React.createElement(Box, { mb: "32px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Input"), /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px"
    },
    program.body && /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(Box, { w: "50%" }, /* @__PURE__ */ React.createElement(
      AceEditor,
      {
        setOptions: {
          useWorker: false
        },
        value: JSON.stringify(
          program.body.circuit || program.body.circuits || program.body,
          null,
          4
        ),
        mode: program.lang,
        theme: "ionq",
        readOnly: true,
        name: "CodeDisplay",
        editorProps: {
          $blockScrolling: true
        },
        wrapEnabled: true,
        fontSize: 16,
        lineHeight: 18.5,
        style: {
          width: "100%",
          height: "250px",
          fontFamily: "'Ubuntu Mono', monospace"
        }
      }
    )), program.type === "circuit" && program.body.circuit && /* @__PURE__ */ React.createElement(Box, { w: "50%" }, /* @__PURE__ */ React.createElement(
      IonVisualizer,
      {
        type: job.type,
        title: job.name || "Untitled",
        visualizerHeight: "250px",
        code: program.body,
        language: program.lang,
        job,
        script: program,
        results
      }
    )))
  )), /* @__PURE__ */ React.createElement(Box, { mb: "64px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-5", mb: "8px", color: "gray.800" }, "Complete response"), /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px"
    },
    /* @__PURE__ */ React.createElement(
      Console,
      {
        removeBottomPadding: true,
        consoleHeight: null,
        consoleData: job
      }
    )
  )));
};
