import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import { AbsoluteCenter, Spinner, useOutsideClick } from "@chakra-ui/react";
import "./HeaderExtendedMenu.css";
import { pageAnalyticsIdentify } from "../../util/analytics";
import {
  useIsOwner,
  useMyEmail,
  useMyId,
  useMyOrganization,
  useMyRole
} from "../shared/Me/useMe";
import { useApi } from "../../Api/ApiProvider.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FEATURE_FLAG } from "../App/featureFlags.js";
const { REACT_APP_IONQ_WEBSITE_BASE_URL } = window._dynamic_env_;
export const HeaderExtendedMenu = ({ closeMobileMenu }) => {
  const [extendedMenuIsOpen, setExtendedMenuIsOpen] = useState(false);
  const { onSessionExpire } = useApi();
  const menuRef = useRef(null);
  const flags = useFlags();
  const showBillingEngine = flags[FEATURE_FLAG.BILLING_ENGINE_MVP];
  const toggleExtendedMenu = () => {
    setExtendedMenuIsOpen(!extendedMenuIsOpen);
  };
  const closeExtendedMenu = () => {
    setExtendedMenuIsOpen(false);
  };
  const logoutUser = () => {
    closeMobileMenu();
    onSessionExpire();
  };
  const email = useMyEmail();
  const role = useMyRole();
  const id = useMyId();
  const isOwner = useIsOwner();
  const organization = useMyOrganization();
  useEffect(() => {
    pageAnalyticsIdentify({
      id,
      role,
      organization
    });
  }, [id, role, organization]);
  useOutsideClick({
    ref: menuRef,
    handler: () => {
      setExtendedMenuIsOpen(false);
    }
  });
  if (!email || !role || !organization)
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, { color: "gray.50" }));
  return /* @__PURE__ */ React.createElement("span", { className: "HeaderExtendedMenu", ref: menuRef }, /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `extended-menu-toggle ${extendedMenuIsOpen ? "-active" : ""}`,
      "data-testid": "HeaderExtendedMenuToggle",
      onClick: toggleExtendedMenu,
      onKeyDown: toggleExtendedMenu,
      tabIndex: "0"
    },
    /* @__PURE__ */ React.createElement(
      Gravatar,
      {
        email,
        size: 22,
        rating: "pg",
        default: "retro",
        className: "gravatar"
      }
    )
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `extended-menu ${extendedMenuIsOpen ? "-visible" : ""}`
    },
    /* @__PURE__ */ React.createElement("div", { className: "extended-menu-link-group" }, /* @__PURE__ */ React.createElement(
      Gravatar,
      {
        email,
        size: 30,
        rating: "pg",
        default: "retro",
        className: "mobile-menu-gravatar"
      }
    ), /* @__PURE__ */ React.createElement("span", { className: "logged-in-description" }, "Signed in as"), /* @__PURE__ */ React.createElement("span", { className: "logged-in-email" }, email)),
    /* @__PURE__ */ React.createElement("div", { className: "extended-menu-link-group" }, /* @__PURE__ */ React.createElement(
      "span",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: logoutUser
      },
      "Sign out"
    )),
    /* @__PURE__ */ React.createElement("div", { className: "extended-menu-link-group" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        to: "/settings/account"
      },
      "Account Settings"
    ), /* @__PURE__ */ React.createElement(
      Link,
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        to: "/settings/keys"
      },
      "API Keys"
    ), /* @__PURE__ */ React.createElement(React.Fragment, null, (organization !== "com.quantumbasel" || organization === "com.quantumbasel" && isOwner) && /* @__PURE__ */ React.createElement(
      Link,
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        to: `/organization/${organization}/members`
      },
      "Organization Members"
    ), isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, showBillingEngine && /* @__PURE__ */ React.createElement(
      Link,
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        to: `/organization/${organization}/spend`
      },
      "Organization Spend"
    ), /* @__PURE__ */ React.createElement(
      Link,
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        to: `/organization/${organization}/settings`
      },
      "Organization Settings"
    )))),
    /* @__PURE__ */ React.createElement("div", { className: "extended-menu-link-group" }, /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        href: "https://docs.ionq.com"
      },
      "Documentation"
    ), /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        href: "https://docs.ionq.com/api-reference/v0.3/introduction"
      },
      "API Reference"
    ), /* @__PURE__ */ React.createElement(
      "button",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: (e) => {
          e.preventDefault();
          if (window.appzi !== void 0) {
            window.appzi.openSurvey(
              "ffb8e358-0d74-4a01-b572-47e2fd2bcbc4"
            );
          }
          closeExtendedMenu();
        }
      },
      "Share Feedback"
    ), /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        href: `https://support.ionq.com/`
      },
      "Support"
    )),
    /* @__PURE__ */ React.createElement("div", { className: "extended-menu-link-group -small-links" }, /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/eula`
      },
      "End User License Agreement"
    ), /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/terms`
      },
      "Terms of Service"
    ), /* @__PURE__ */ React.createElement(
      "a",
      {
        className: "extended-menu-link",
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/privacy`
      },
      "Privacy Policy"
    ))
  ));
};
