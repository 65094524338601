export const createJobsParamsObject = ({ filters, nextParam, limit }) => {
  const filtersForParams = limit && !isNaN(limit) ? {
    ...filters,
    limit
  } : filters;
  [
    "dateCheckbox",
    "filterDate",
    "filterEndDate",
    "statusCheckbox",
    "submittedCheckbox",
    "projectIdCheckbox",
    "nameCheckbox",
    "requestCheckbox",
    "idCheckbox",
    "metadataCheckbox",
    "metadataKey",
    "metadataValue"
  ].forEach((k) => delete filtersForParams[k]);
  if (nextParam) {
    filtersForParams.next = nextParam;
  }
  if (filters.metadataKey) {
    filtersForParams.metadata = `${filters.metadataKey}=${filters.metadataValue || ""}`;
  }
  if (filters.date && filters.filterDate) {
    filtersForParams.date = `${filters.date}=${filters.filterDate}${filters.filterEndDate ? "|" + filters.filterEndDate : ""}`;
  }
  if (!filtersForParams.id) {
    delete filtersForParams.id;
  }
  if (!filtersForParams.request) {
    delete filtersForParams.request;
  }
  if (!filtersForParams.projectId) {
    delete filtersForParams.projectId;
  }
  if (!filtersForParams.submitted) {
    delete filtersForParams.submitted;
  }
  if (!filtersForParams.date) {
    delete filtersForParams.date;
  }
  if (!filtersForParams.name) {
    delete filtersForParams.name;
  }
  if (!filtersForParams.status) {
    delete filtersForParams.status;
  }
  return filtersForParams;
};
