import React, { useRef } from "react";
import { Table, Tbody, Thead, Tr, Th, useToast, Box } from "@chakra-ui/react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import { LoadingView } from "./LoadingView.js";
import { DefaultEmptyView } from "./DefaultEmptyView.js";
import { headerGroupHeaderMapFunction } from "./headerGroupHeaderMapFunction.js";
import { IonTableContainer } from "./IonTableContainer.js";
import { IonTableLabelHeading } from "./IonTableLabelHeading.js";
import { tableRowModelMapCellsFunction } from "./tableRowModelMapCellsFunction.js";
import { getColumns } from "./getColumns.js";
const columnHelper = createColumnHelper();
export const IonTable = ({
  emptyView,
  isLoadingItems,
  isRowSelectEnabled,
  keyLabels,
  label,
  onRowClick,
  rows,
  rowSelection,
  setRowSelection
}) => {
  const [sorting, setSorting] = React.useState([]);
  const toast = useToast();
  const toastIdRef = useRef();
  const columns = getColumns({ columnHelper, keyLabels });
  const table = useReactTable({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: isRowSelectEnabled,
    getRowId: (row) => row.id,
    state: {
      rowSelection,
      sorting
    }
  });
  const numberOfSelectedRows = rowSelection && Object.keys(rowSelection).length || 0;
  return /* @__PURE__ */ React.createElement(Box, { shadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)" }, /* @__PURE__ */ React.createElement(
    IonTableLabelHeading,
    {
      label,
      numberOfRows: rows.length,
      numberOfSelectedRows
    }
  ), /* @__PURE__ */ React.createElement(IonTableContainer, null, /* @__PURE__ */ React.createElement(Table, { variant: "ionq" }, /* @__PURE__ */ React.createElement(Thead, null, table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ React.createElement(Tr, { key: headerGroup.id }, isRowSelectEnabled && /* @__PURE__ */ React.createElement(Th, { padding: "0 12px" }), headerGroup.headers.map((header, index) => {
    return {
      header,
      index,
      isRowSelectEnabled
    };
  }).map(headerGroupHeaderMapFunction)))), /* @__PURE__ */ React.createElement(Tbody, null, table.getRowModel().rows.map((row) => {
    return {
      isRowSelectEnabled,
      onRowClick,
      row,
      toast,
      toastIdRef
    };
  }).map(tableRowModelMapCellsFunction))), isLoadingItems && rows.length === 0 && /* @__PURE__ */ React.createElement(LoadingView, null), !isLoadingItems && rows.length === 0 && (emptyView || /* @__PURE__ */ React.createElement(DefaultEmptyView, null))));
};
