import React, { useContext, useMemo, useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spacer, Spinner, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FilterButton } from "../shared/FilterButton";
import { useOrganization } from "../shared/Organization/useOrganization";
import api from "../../api";
import { MdArrowOutward, MdOutlineCancel } from "react-icons/md";
import { JobsEmptyView } from "../shared/JobsEmptyView.js";
import { sharedColumns } from "../shared/sharedColumns.js";
import { JobsRowActionPopover } from "../shared/JobsRowActionPopover.js";
import { IdText } from "../shared/IdText/index.js";
import { useProjects } from "../Projects/hooks/useProjects.js";
import { getProjectFromId } from "../APIKeys/APIKeysForProjectsMembers.js";
import { useBaseModal } from "../shared/baseModals/useBaseModal.js";
import { MODALS } from "../shared/baseModals/constants.js";
import {
  cancelableItemsArray,
  selectedProjectsSet
} from "../CancelMultipleJobsConfirmationModal/cancelJobsUtils.js";
import { getNoiseModelForRow } from "../shared/getNoiseModelForRow.js";
import { NameCell } from "../shared/IonTableCells/NameCell.js";
import { EmptyFilterView } from "../shared/IonTable/EmptyFilterView.js";
import { DefaultErrorView } from "../shared/IonTable/DefaultErrorView.js";
import { MyJobsContext } from "../../contexts/MyJobsContext.js";
import { createJobsParamsObject } from "../../util/createJobsParamsObject.js";
import { IonPaginatedTable } from "../shared/IonTable/IonPaginatedTable.js";
const JOBS_TABLE_KEY_LABELS = [
  {
    key: "request",
    label: "Date",
    isSortable: true
  },
  {
    key: "status",
    label: "Status"
  },
  { key: "name", label: "Name" },
  { key: "target", label: "Backend" },
  {
    key: "id",
    label: "ID",
    isCopyable: true,
    getDisplay: (id) => /* @__PURE__ */ React.createElement(IdText, { id })
  },
  { key: "type", label: "Type" },
  {
    key: "project_name",
    label: "Project",
    isTruncated: true
  },
  {
    key: "usage",
    label: "Cost"
  },
  { key: "action", label: "" }
];
export const MyJobs = () => {
  const navigate = useNavigate();
  const { open: openCancelMultipleJobsConfirmation } = useBaseModal(
    MODALS.cancelMultipleJobsConfirmation
  );
  const { open: openCancelJobConfirmationModal } = useBaseModal(
    MODALS.cancelJobConfirmation
  );
  const [isExporting, setIsExporting] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  const {
    jobs,
    filters,
    setFilters,
    setIsLoading,
    error: jobsError,
    next
  } = useContext(MyJobsContext);
  const { organization, error: organizationError } = useOrganization();
  const { projects, error: projectsError } = useProjects();
  const hasAccess = !!organization?.hasAccessToPaidBackend;
  const myJobsFilterOptions = [
    {
      name: "Date",
      property: "date",
      isDate: true
    },
    {
      name: "Status",
      property: "status",
      options: [
        "completed",
        "canceled",
        "failed",
        "running",
        "submitted",
        "ready"
      ]
    },
    {
      name: "Name",
      property: "name",
      label: "contains"
    },
    {
      name: "ID",
      property: "id",
      label: "enter full ID"
    },
    {
      name: "Project",
      property: "projectId",
      label: "select project",
      options: projects.map((p) => {
        return {
          label: p.name,
          value: p.id
        };
      })
    },
    {
      name: "Metadata",
      property: "metadata",
      isMetadata: true
    }
  ];
  const rows = useMemo(() => {
    return jobs.map((row) => {
      return {
        ...sharedColumns({
          row,
          hasAccessToPaidBackend: hasAccess,
          noiseModelName: getNoiseModelForRow(row)
        }),
        ...{
          onRowClick: () => navigate(`/jobs/${row.id}`, { state: { isMyJobs: true } }),
          action: /* @__PURE__ */ React.createElement(JobsRowActionPopover, { job: row }),
          project_name: /* @__PURE__ */ React.createElement(
            NameCell,
            {
              name: getProjectFromId({
                projects,
                projectId: row.project_id
              })?.name,
              width: "301px"
            }
          )
        }
      };
    });
  }, [jobs, hasAccess, projects]);
  const updateFilters = (filters2) => {
    setIsLoading(true);
    setFilters(filters2);
  };
  const error = jobsError || projectsError || organizationError;
  if (error) return /* @__PURE__ */ React.createElement(Text, null, error);
  const selectedCancelableItems = cancelableItemsArray({ jobs, rowSelection });
  const selectedProjects = selectedProjectsSet({
    selectedCancelableItems,
    projects,
    jobs
  });
  return /* @__PURE__ */ React.createElement(Box, { mb: "64px" }, /* @__PURE__ */ React.createElement(Flex, { mb: "12px" }, /* @__PURE__ */ React.createElement(
    FilterButton,
    {
      filters,
      filterOptions: myJobsFilterOptions,
      setFilters: updateFilters
    }
  ), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "md",
      variant: "grey",
      leftIcon: /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdArrowOutward,
          width: "16px",
          height: "16px",
          color: "gray.600"
        }
      ),
      onClick: () => {
        setIsExporting(true);
        const params = createJobsParamsObject({
          filters,
          nextParam: next
        });
        api.exportJobs({
          params,
          selectedIds: Object.keys(rowSelection)
        }).then(
          () => {
            setRowSelection([]);
            setIsExporting(false);
          },
          () => setIsExporting(false)
        );
      }
    },
    isExporting ? "Exporting" : "Export",
    isExporting && /* @__PURE__ */ React.createElement(Spinner, { size: "sm", ml: "0.3em" })
  ), selectedCancelableItems.length > 0 && /* @__PURE__ */ React.createElement(
    Button,
    {
      ml: "8px",
      size: "md",
      variant: "grey",
      color: "uiRed",
      leftIcon: /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlineCancel,
          width: "16px",
          height: "16px",
          color: "uiRed"
        }
      ),
      onClick: () => {
        if (selectedCancelableItems.length === 1) {
          openCancelJobConfirmationModal({
            jobId: selectedCancelableItems[0],
            ctaPreAction: () => {
              setIsCanceling(true);
            },
            ctaSuccessAction: () => {
              setRowSelection([]);
              setIsCanceling(false);
            },
            ctaErrorAction: () => {
              setIsCanceling(false);
            }
          });
          return;
        }
        openCancelMultipleJobsConfirmation({
          jobs: selectedCancelableItems,
          projects: [...selectedProjects],
          ctaPreAction: () => {
            setIsCanceling(true);
          },
          ctaSuccessAction: () => {
            setRowSelection([]);
            setIsCanceling(false);
          },
          ctaErrorAction: () => {
            setIsCanceling(false);
          }
        });
      }
    },
    isCanceling ? "Canceling" : "Cancel",
    isCanceling && /* @__PURE__ */ React.createElement(Spinner, { size: "sm", ml: "0.3em" })
  )), /* @__PURE__ */ React.createElement(
    IonPaginatedTable,
    {
      context: MyJobsContext,
      emptyView: error ? /* @__PURE__ */ React.createElement(DefaultErrorView, { error }) : Object.keys(filters).length > 0 ? /* @__PURE__ */ React.createElement(EmptyFilterView, null) : /* @__PURE__ */ React.createElement(JobsEmptyView, null),
      isRowSelectEnabled: true,
      keyLabels: JOBS_TABLE_KEY_LABELS,
      label: "Job",
      onRowClick: (row) => row.onRowClick(),
      rows: error ? [] : rows,
      rowSelection,
      setRowSelection
    }
  ));
};
