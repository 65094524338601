import React, { useMemo } from "react";
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import { IonTable } from "../shared/IonTable";
import { PageTitle } from "../shared/PageTitle";
import { useIsOwner, useMyEmail } from "../shared/Me/useMe";
import { RowActionPopover } from "../shared/RowActionPopover";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { MODALS } from "../shared/baseModals/constants";
import { RemoveUserFromProjectModal } from "./modals/RemoveUserFromProjectModal";
import { useProjectMembers } from "./hooks/useProjectMembers";
import { useProject } from "./hooks/useProject";
import { AddNewProjectMemberModal } from "./modals/AddNewProjectMemberModal";
import { USD } from "../../util/format";
import { useOrganization } from "../shared/Organization/useOrganization";
import projectMembersWhiteBackground from "../../assets/images/Project_Members_White_Background.png";
const PROJECT_MEMBERS_TABLE_KEY_LABELS = [
  { key: "name", label: "Member" },
  { key: "role", label: "Role" },
  { key: "quotaUsageUsd", label: "Total spend" },
  { key: "action", label: "" }
];
const ProjectMembersRowActionPopover = ({ projectName, userId, userName }) => {
  const isOwner = useIsOwner();
  const modal = useBaseModal(MODALS.removeMemberFromProject);
  const menuItems = [
    {
      key: "remove",
      variant: "DANGER",
      node: "Remove from project",
      onClick: () => {
        modal.open({
          userId,
          userName,
          projectName
        });
      }
    }
  ];
  return isOwner && /* @__PURE__ */ React.createElement(RowActionPopover, { menuItems });
};
export const getDisplayName = ({ firstName, lastName, isYou }) => {
  const displayName = [firstName, lastName, isYou ? "(You)" : void 0].filter((part) => part).join(" ");
  return displayName || null;
};
const getDisplayRows = ({
  rawRows,
  myEmail,
  projectName,
  hasAccessToPaidBackend
}) => rawRows.sort(
  (a, b) => getShortMemberName(a).toLowerCase() > getShortMemberName(b).toLowerCase()
).map((rawRow) => {
  const isYou = rawRow.email === myEmail;
  const { id, email, firstName, lastName, isOwner, quotaUsageUsd } = rawRow;
  const displayName = getDisplayName({
    firstName,
    lastName,
    isYou
  });
  const name = /* @__PURE__ */ React.createElement(React.Fragment, null, displayName && /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", as: "span" }, displayName), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-9", color: "gray.600", mt: "2px" }, email));
  return {
    name: /* @__PURE__ */ React.createElement(Text, { width: "500px" }, name),
    quotaUsageUsd: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", w: "150px" }, USD.format(hasAccessToPaidBackend ? quotaUsageUsd : 0)),
    role: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark", w: "150px" }, isOwner ? "Organization owner" : "Project member"),
    action: /* @__PURE__ */ React.createElement(
      ProjectMembersRowActionPopover,
      {
        userId: id,
        userName: getShortMemberName({
          firstName,
          lastName,
          email
        }),
        projectName
      }
    )
  };
});
export const getShortMemberName = ({ firstName, lastName, email }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName || lastName || email;
};
export const ProjectMembers = () => {
  const { organization, isLoading: isOrganizationLoading } = useOrganization();
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError
  } = useProject();
  const {
    projectMembers,
    isLoading: isProjectMembersLoading,
    error: projectMembersError,
    addProjectMembers,
    removeProjectMember
  } = useProjectMembers(project?.id);
  const { open: openInviteNewMembersModal } = useBaseModal(
    MODALS.inviteNewMembers
  );
  const myEmail = useMyEmail();
  const isOwner = useIsOwner();
  const hasAccessToPaidBackend = organization?.hasAccessToPaidBackend;
  const rows = useMemo(() => {
    return getDisplayRows({
      rawRows: projectMembers,
      hasAccessToPaidBackend,
      myEmail,
      projectName: project.name
    });
  }, [hasAccessToPaidBackend, projectMembers, project.name, myEmail]);
  if (isProjectLoading || isOrganizationLoading || isProjectMembersLoading)
    return /* @__PURE__ */ React.createElement(Box, { height: "70vh" }, /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, null)));
  const error = projectError || isOrganizationLoading || projectMembersError;
  if (error)
    return /* @__PURE__ */ React.createElement(Box, { pt: "30px" }, /* @__PURE__ */ React.createElement(Text, null, error));
  const openInviteMembersModal = () => {
    openInviteNewMembersModal({
      projectName: project.name,
      projectId: project.id
    });
  };
  return /* @__PURE__ */ React.createElement(Box, { pt: "20px", mb: "64px" }, /* @__PURE__ */ React.createElement(Flex, { justifyContent: "space-between" }, /* @__PURE__ */ React.createElement(Center, { height: "38px" }, /* @__PURE__ */ React.createElement(PageTitle, null, "Project Members")), isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "blue",
      size: "lg",
      onClick: openInviteMembersModal
    },
    "Add members"
  ), /* @__PURE__ */ React.createElement(
    RemoveUserFromProjectModal,
    {
      removeProjectMember
    }
  ), /* @__PURE__ */ React.createElement(
    AddNewProjectMemberModal,
    {
      addProjectMembers,
      projectMembers,
      projectName: project.name
    }
  ))), /* @__PURE__ */ React.createElement(
    IonTable,
    {
      label: "Member",
      rows,
      keyLabels: PROJECT_MEMBERS_TABLE_KEY_LABELS,
      emptyView: /* @__PURE__ */ React.createElement(
        ProjectMembersEmptyView,
        {
          openInviteMembersModal
        }
      )
    }
  ));
};
const ProjectMembersEmptyView = ({ openInviteMembersModal }) => {
  return /* @__PURE__ */ React.createElement(VStack, { w: "458px", ml: "auto", mr: "auto" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-2",
      color: "gray.900",
      textAlign: "center",
      mb: "20px",
      mt: "40px"
    },
    "All of your project members in one place."
  ), /* @__PURE__ */ React.createElement(Image, { src: projectMembersWhiteBackground, w: 300, mb: "30px" }), /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "body-3",
      color: "gray.900",
      textAlign: "center",
      mb: "30px"
    },
    "The cloud console shows you a list of all project members invited to this particular project, but it looks like you haven\u2019t invited anyone yet!"
  ), /* @__PURE__ */ React.createElement(Button, { size: "lg", variant: "blue", onClick: openInviteMembersModal }, "Invite members"));
};
