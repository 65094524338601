import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { IonTooltip } from "../IonTooltip/index.js";
import { MdErrorOutline } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
const MAX_NAME_LENGTH = 54;
export const NameCell = ({ name, maxLength, width, errorMitigation }) => {
  const element = /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "body-table-name",
      color: name ? "ionDark" : "gray.400",
      noOfLines: 2,
      wordBreak: "break-all",
      w: width ?? "219px"
    },
    name ?? "Untitled"
  );
  return name?.length > (maxLength ?? MAX_NAME_LENGTH) ? /* @__PURE__ */ React.createElement(HStack, null, /* @__PURE__ */ React.createElement(IonTooltip, { label: name }, /* @__PURE__ */ React.createElement("span", null, element)), errorMitigation ? /* @__PURE__ */ React.createElement(IonTooltip, { label: "Debiased" }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Icon, { as: MdErrorOutline }))) : null) : element;
};
