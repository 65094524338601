import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { useProjectJobs } from "../components/Projects/hooks/useProjectJobs.js";
import { useLocation } from "react-router-dom";
import {
  DETAIL_PAGE_SIZE,
  DETAIL_PAGE_SIZE_KEY,
  PROJECT_JOBS_PAGE_SIZE,
  PROJECT_JOBS_PAGE_SIZE_KEY
} from "../components/shared/IonTable/pageSize.js";
export const ProjectJobsContext = createContext({
  jobs: [],
  setJobs: () => {
  },
  filters: [],
  setFilters: () => {
  },
  hasMore: false,
  next: null,
  isLoading: false,
  jobsError: null,
  loadMore: () => {
  },
  setIsLoading: () => {
  },
  pageSizeKey: ""
});
export const ProjectJobsProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);
  const { pathname } = useLocation();
  const isDetailPage = pathname.split("/").filter(Boolean)[0] === "jobs";
  const [pageSizeKey, setPageSizeKey] = useState(
    isDetailPage ? DETAIL_PAGE_SIZE_KEY : PROJECT_JOBS_PAGE_SIZE_KEY
  );
  const [limit, setLimit] = useState(
    isDetailPage ? DETAIL_PAGE_SIZE() : PROJECT_JOBS_PAGE_SIZE()
  );
  const {
    hasMore,
    next,
    jobs,
    isLoading,
    loadMore,
    error,
    setIsLoading,
    setProjectId,
    projectId
  } = useProjectJobs({
    filters,
    id: null,
    limit
  });
  useEffect(() => {
    const splitURL = pathname.split("/").filter(Boolean);
    const [projectsPath, id] = splitURL;
    if (limit !== (isDetailPage ? DETAIL_PAGE_SIZE() : PROJECT_JOBS_PAGE_SIZE())) {
      setLimit(
        isDetailPage ? DETAIL_PAGE_SIZE() : PROJECT_JOBS_PAGE_SIZE()
      );
    }
    if (pageSizeKey !== (isDetailPage ? DETAIL_PAGE_SIZE_KEY : PROJECT_JOBS_PAGE_SIZE_KEY)) {
      setPageSizeKey(
        isDetailPage ? DETAIL_PAGE_SIZE_KEY : PROJECT_JOBS_PAGE_SIZE_KEY
      );
    }
    if (splitURL.length !== 2 || projectsPath !== "projects" || id.length !== 36)
      return;
    if (id !== projectId) {
      setIsLoading(true);
      setProjectId(id);
    }
  }, [pathname, projectId, setIsLoading, setProjectId, limit, pageSizeKey]);
  return /* @__PURE__ */ React.createElement(
    ProjectJobsContext.Provider,
    {
      value: {
        jobs,
        filters,
        setFilters,
        hasMore,
        next,
        isLoading,
        error,
        loadMore,
        setIsLoading,
        limit,
        setLimit,
        pageSizeKey
      }
    },
    children
  );
};
