import React, { useContext, useEffect, useRef, useState } from "react";
import { Table, Tbody, Thead, Tr, Th, useToast, Box } from "@chakra-ui/react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper
} from "@tanstack/react-table";
import { LoadingView } from "./LoadingView.js";
import { DefaultEmptyView } from "./DefaultEmptyView.js";
import { PreviousAndNext } from "./PreviousAndNext.js";
import { headerGroupHeaderMapFunction } from "./headerGroupHeaderMapFunction.js";
import { IonTableContainer } from "./IonTableContainer.js";
import { IonTableLabelHeading } from "./IonTableLabelHeading.js";
import { tableRowModelMapCellsFunction } from "./tableRowModelMapCellsFunction.js";
import { getColumns } from "./getColumns.js";
const columnHelper = createColumnHelper();
export const IonPaginatedTable = ({
  context,
  emptyView,
  isRowSelectEnabled,
  keyLabels,
  label,
  onRowClick,
  rows,
  rowSelection,
  setRowSelection
}) => {
  const { isLoading, limit } = useContext(context);
  const [sorting, setSorting] = React.useState([]);
  const toast = useToast();
  const toastIdRef = useRef();
  const columns = getColumns({ columnHelper, keyLabels });
  const table = useReactTable({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: isRowSelectEnabled,
    getRowId: (row) => row.id,
    state: {
      rowSelection,
      sorting
    }
  });
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const numberOfSelectedRows = rowSelection && Object.keys(rowSelection).length || 0;
  useEffect(() => {
    if (!isLoading && // when the index is too high for the number of rows, reset to the first page
    limit * currentPageIndex + 1 > rows.length && currentPageIndex > 0) {
      setCurrentPageIndex(0);
    }
  }, [rows.length, limit, currentPageIndex, isLoading]);
  const isEmpty = rows.length === 0;
  return /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
    IonTableLabelHeading,
    {
      label,
      numberOfRows: rows.length,
      numberOfSelectedRows
    }
  ), /* @__PURE__ */ React.createElement(IonTableContainer, null, /* @__PURE__ */ React.createElement(Table, { variant: "ionq" }, /* @__PURE__ */ React.createElement(Thead, null, table.getHeaderGroups().map((headerGroup) => /* @__PURE__ */ React.createElement(Tr, { key: headerGroup.id }, isRowSelectEnabled && /* @__PURE__ */ React.createElement(Th, { padding: "0 12px" }), headerGroup.headers.map((header, index) => {
    return {
      header,
      index,
      isRowSelectEnabled
    };
  }).map(headerGroupHeaderMapFunction)))), /* @__PURE__ */ React.createElement(Tbody, null, table.getRowModel().rows.map((row) => {
    return {
      currentPageIndex,
      isRowSelectEnabled,
      limit,
      onRowClick,
      row,
      toast,
      toastIdRef
    };
  }).map(tableRowModelMapCellsFunction))), isLoading && isEmpty && /* @__PURE__ */ React.createElement(LoadingView, null), !isLoading && isEmpty && (emptyView || /* @__PURE__ */ React.createElement(DefaultEmptyView, null))), /* @__PURE__ */ React.createElement(
    PreviousAndNext,
    {
      context,
      rows,
      currentPageIndex,
      setCurrentPageIndex
    }
  ));
};
