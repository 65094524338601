import React from "react";
import { TableContainer } from "@chakra-ui/react";
export const IonTableContainer = ({ children }) => /* @__PURE__ */ React.createElement(
  TableContainer,
  {
    minW: "100%",
    display: "block",
    overflowX: "scroll",
    borderRadius: "5px",
    bg: "white",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    border: "1px solid #e8e8e8",
    borderBottom: "2px solid #e8e8e8",
    borderColor: "gray.200",
    mb: "12px"
  },
  children
);
