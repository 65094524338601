import React, { useContext } from "react";
import { Box, Container, Text } from "@chakra-ui/react";
import { addAQ, backendSort, getAvgTimeInQueue } from "./utils";
import { SystemsBackEnd } from "./SystemsBackend";
import { IonTabs } from "../shared/IonTabs";
import { StatusContext } from "../shared/Status/context.js";
import { useLocation } from "react-router-dom";
import { LoadingView } from "../shared/IonTable/LoadingView.js";
export const Systems = () => {
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "gray.50",
      h: [117, 117, 125],
      borderBottom: "1px solid #E0E0E3",
      pt: ["28px", "28px", "36px"]
    },
    /* @__PURE__ */ React.createElement(Container, { maxW: "100%", id: "systems" }, /* @__PURE__ */ React.createElement(
      Box,
      {
        maxW: "1536px",
        id: "systems-header",
        ml: [0, "auto"],
        mr: [0, "auto"]
      },
      /* @__PURE__ */ React.createElement(
        Text,
        {
          textStyle: "heading-3",
          color: "gray.900",
          mb: "24px",
          ml: 0
        },
        "Backends"
      ),
      /* @__PURE__ */ React.createElement(SystemsDashboard, null)
    ))
  );
};
const System = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/").filter(Boolean).pop();
  const { data, loading, error } = useContext(StatusContext);
  if (loading || loading && !data?.backends.length) {
    return /* @__PURE__ */ React.createElement(LoadingView, null);
  }
  if (error || !data?.backends.length) {
    return null;
  }
  const tabFilter = (item) => {
    return id === "" || id === "backends" || id === "qpus" ? item.backend !== "simulator" : true;
  };
  const isSimulator = id === "simulators";
  const simulatorBackend = data?.backends.find(
    (item) => item.backend === "simulator"
  );
  const simulatorWaitTime = getAvgTimeInQueue(
    simulatorBackend.average_queue_time
  );
  const sortedBackends = data?.backends.map(addAQ).sort(backendSort).filter(tabFilter);
  return sortedBackends.map((backend) => /* @__PURE__ */ React.createElement(
    SystemsBackEnd,
    {
      noiseModels: simulatorBackend?.noise_models,
      key: backend.backend,
      isSimulator,
      simulatorWaitTime: isSimulator ? simulatorWaitTime : null,
      backend,
      characterizations: data?.characterizations,
      isFreeAccess: data?.isFreeAccess
    }
  ));
};
const SystemsDashboard = () => {
  return /* @__PURE__ */ React.createElement(IonTabs, { baseUrl: `/backends`, tabs: TABS });
};
const TABS = [
  { name: "QPUs", Component: System },
  { name: "Simulators", Component: System }
];
