import React, { useState } from "react";
import SettingsButton from "../SettingsButton/SettingsButton";
import SettingsButtonSelect from "../SettingsButton/SettingsButtonSelect";
import { downloadPDF } from "../../util/downloadPDF.js";
import { Spinner } from "@chakra-ui/react";
export const IonVisualizerSettings = (props) => {
  const [openSettingsOptions, setOpenSettingsOptions] = useState(null);
  const [downloadSpinnerIsVisible, setDownloadSpinnerIsVisible] = useState(false);
  const downloadSVG = (svg, name) => {
    const svgData = svg.outerHTML;
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';
    const svgBlob = new Blob([preface, svgData], {
      type: "image/svg+xml;charset=utf-8"
    });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const showSettingsOptions = (options) => {
    if (openSettingsOptions === options) {
      setOpenSettingsOptions(null);
    } else {
      setOpenSettingsOptions(options);
    }
  };
  const setOrientation = (orientation) => {
    props.setVisualizerOrientation(orientation);
  };
  const updateZoom = (val) => {
    props.setZoom(val);
  };
  const downloadVisualization = (type) => {
    if (type === "svg") {
      downloadSVG(props.svgRef.current, props.svgName);
    } else if (type === "pdf") {
      setDownloadSpinnerIsVisible(true);
      downloadPDF(props.svgRef.current, props.svgName).finally(
        () => setDownloadSpinnerIsVisible(false)
      );
    } else {
      alert(
        "TODO: add a download handler that supports " + type + " and other download options"
      );
    }
  };
  const orientationOptions = {
    "vertical view": "vertical",
    "horizontal view": "horizontal"
  };
  const zoomOptions = {
    "150%": 1.5,
    "125%": 1.25,
    "100%": 1,
    "75%": 0.75,
    "50%": 0.5
  };
  const downloadOptions = {
    "download as SVG": "svg",
    "download as PDF": "pdf"
  };
  return /* @__PURE__ */ React.createElement("div", { className: "VisualizerSettings" }, /* @__PURE__ */ React.createElement(
    SettingsButton,
    {
      icon: "view",
      open: openSettingsOptions === "orientation",
      name: "visualizer-view-mode",
      onClick: () => {
        showSettingsOptions("orientation");
      }
    },
    props.isHorizontal ? "horizontal" : "vertical",
    openSettingsOptions === "orientation" && /* @__PURE__ */ React.createElement(
      SettingsButtonSelect,
      {
        options: orientationOptions,
        onSelect: setOrientation,
        active: props.isHorizontal ? "horizontal" : "vertical"
      }
    )
  ), /* @__PURE__ */ React.createElement(
    SettingsButton,
    {
      icon: "zoom",
      open: openSettingsOptions === "zoom",
      name: "visualizer-zoom",
      onClick: () => {
        showSettingsOptions("zoom");
      }
    },
    `${props.zoom * 100}%`,
    openSettingsOptions === "zoom" && /* @__PURE__ */ React.createElement(
      SettingsButtonSelect,
      {
        options: zoomOptions,
        onSelect: updateZoom,
        active: props.zoom
      }
    )
  ), /* @__PURE__ */ React.createElement(
    SettingsButton,
    {
      disabled: !(props.code && props.circuit),
      icon: "download",
      open: openSettingsOptions === "download",
      name: "download-editor-text",
      onClick: () => {
        showSettingsOptions("download");
      }
    },
    "download",
    downloadSpinnerIsVisible && /* @__PURE__ */ React.createElement(Spinner, { boxSize: "8px", mt: "5px", ml: "5px" }),
    openSettingsOptions === "download" && /* @__PURE__ */ React.createElement(
      SettingsButtonSelect,
      {
        options: downloadOptions,
        onSelect: downloadVisualization
      }
    )
  ));
};
