import pdfMake from "pdfmake";
const DOM_URL = window.URL || window.webkitURL || window;
const SVG_MIME_TYPE = "image/svg+xml;charset=utf-8";
const PNG_MIME_TYPE = "image/png";
const OCTET_STREAM = "image/octet-stream";
const getDownloadPDFURL = (svg) => {
  const svgString = new XMLSerializer().serializeToString(svg);
  const svgBlob = new Blob([svgString], {
    type: SVG_MIME_TYPE
  });
  return DOM_URL.createObjectURL(svgBlob);
};
export const downloadPDF = (svg, name) => new Promise((resolve, reject) => {
  const canvas = document.getElementById("canvas");
  const image = new Image();
  const context = canvas.getContext("2d");
  const url = getDownloadPDFURL(svg);
  image.width = svg.width.baseVal.value;
  image.height = svg.height.baseVal.value;
  image.src = url;
  image.onload = function() {
    canvas.width = image.width * 4;
    canvas.height = image.height * 4;
    context.drawImage(image, 0, 0, image.width * 4, image.height * 4);
    DOM_URL.revokeObjectURL(url);
    const imgURI = canvas.toDataURL(PNG_MIME_TYPE).replace(PNG_MIME_TYPE, OCTET_STREAM);
    pdfMake.createPdf({
      content: [{ image: "svgImage" }],
      images: { svgImage: imgURI },
      pageSize: {
        width: svg.width.baseVal.value * 4 + 100,
        height: svg.height.baseVal.value * 4 + 100
      }
    }).download(`${name}.pdf`, resolve);
  };
  image.onerror = reject;
});
