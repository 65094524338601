import React from "react";
import { Box, Flex, Progress, Text } from "@chakra-ui/react";
import { IonPanel } from "../shared/IonPanel";
import { getFormattedBudget } from "../../util/format";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { MODALS } from "../shared/baseModals/constants";
import { useIsOwner } from "../shared/Me/useMe";
import { useProject } from "../Projects/hooks/useProject";
import { useOrganization } from "../shared/Organization/useOrganization";
export const BudgetMeter = () => {
  const { project, isLoading: isProjectLoading } = useProject();
  const { organization, isLoading: isOrganizationLoading } = useOrganization();
  const { quotaLimitUsd, quotaUsageUsd, isArchived } = project || {};
  const { usedFormatted, remaining, totalFormatted, percentageUsed } = getFormattedBudget(quotaLimitUsd, quotaUsageUsd);
  const isOwner = useIsOwner();
  const { open: openChangeProjectBudget } = useBaseModal(
    MODALS.changeProjectBudget
  );
  if (isProjectLoading || isOrganizationLoading) return null;
  const hasAccessToPaidBackend = organization?.hasAccessToPaidBackend ?? false;
  const isSpendAndBudgetZero = !quotaLimitUsd && !quotaUsageUsd;
  return /* @__PURE__ */ React.createElement(Box, { mb: "24px" }, /* @__PURE__ */ React.createElement(IonPanel, null, /* @__PURE__ */ React.createElement(Flex, { justifyContent: "space-between" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", color: "ionDark" }, "Total project cost"), hasAccessToPaidBackend && isOwner && !isArchived && /* @__PURE__ */ React.createElement(
    Text,
    {
      cursor: "pointer",
      maxHeight: "21px",
      fontWeight: 400,
      textStyle: "body-3",
      color: "uiBlue",
      _hover: { textDecoration: "underline" },
      onClick: () => openChangeProjectBudget()
    },
    "Change budget"
  )), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Box, { mt: "8px" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "heading-4",
      color: "ionDark",
      lineHeight: "21px",
      mr: "8px"
    },
    usedFormatted
  ), hasAccessToPaidBackend && /* @__PURE__ */ React.createElement(
    Text,
    {
      as: "span",
      textStyle: "body-4",
      color: "gray.600",
      lineHeight: "21px"
    },
    "/ ",
    totalFormatted || "Unlimited"
  )), hasAccessToPaidBackend && /* @__PURE__ */ React.createElement(
    Progress,
    {
      hasStripe: !remaining || isSpendAndBudgetZero,
      colorScheme: isSpendAndBudgetZero ? "gray" : "green",
      mt: "8px",
      mb: "8px",
      value: isSpendAndBudgetZero ? 100 : percentageUsed
    }
  ), hasAccessToPaidBackend && remaining && remaining !== "$0.00" && /* @__PURE__ */ React.createElement(Flex, { justifyContent: "end" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-5", color: "gray.600" }, remaining, " remaining")))));
};
